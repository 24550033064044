import classNames from "clsx";

export type AvatarProps = {
  className?: string;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  name?: string;
};

export default function Avatar({
  className,
  size = "base",
  name,
  ...rest
}: AvatarProps) {
  return (
    <span
      className={classNames(
        "inline-block rounded-full overflow-hidden bg-gray-100",
        {
          "h-6 w-6": size === "xs",
          "h-8 w-8": size === "sm",
          "h-10 w-10": size === "base",
          "h-12 w-12": size === "lg",
          "h-14 w-14": size === "xl",
        },
        className,
      )}
      title={name}
      {...rest}
    >
      <svg
        className="h-full w-full text-gray-300"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
}
