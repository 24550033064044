import useSWR from "swr";

import { AdFrontend, AdSlot, GetAdTypeBySlot } from "@shared/types";

// pid should be unique per page (used by ad server
// to maintain unique ad delivery per page)
const pid = Date.now();

async function fetcher(adSlot: string, position: number) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_ADS_APP_URL}/api/item?slot=${adSlot}&position=${position}&pid=${pid}`,
    {
      credentials: "include",
    },
  );
  if (res.status !== 200) {
    return null;
  }
  const result = await res.json();
  return result;
}

// Inspired by Conditional Type magic found at
// https://artsy.github.io/blog/2018/11/21/conditional-types-in-typescript/
// Look for the ExtractActionParameters type example in there.

export default function useAd<S extends AdSlot>(
  adSlot: S,
  position = 0,
): {
  ad: GetAdTypeBySlot<AdFrontend, S> | undefined;
  isError: boolean;
  isLoading: boolean;
} {
  const { data: ad, error } = useSWR<GetAdTypeBySlot<AdFrontend, S>, unknown>(
    [adSlot, position],
    fetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
  return {
    isError: !!error,
    isLoading: !error && ad === undefined,
    ad,
  };
}
