import { GA_TRACKING_ID } from "./constants";

function createFunctionWithTimeout(callback: () => unknown, timeout?: number) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, timeout || 1000);
  return fn;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/screens
export function screenView({ screenName }: { screenName: string }) {
  gtag("event", "screen_view", {
    screen_name: screenName,
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export function pageView(url: string) {
  // @ts-ignore - gtag typings seem not to be inline with gtag docs
  gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function event(
  name: string,
  params: Record<string, unknown>,
  callback?: () => unknown,
) {
  gtag("event", name, {
    ...params,
    event_callback: callback ? createFunctionWithTimeout(callback) : undefined,
  });
}
