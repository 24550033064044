import classNames from "clsx";
import { PropsWithChildren } from "react";

import { As } from "./types";

type ContainerProps = PropsWithChildren<{
  className?: string;
  as?: As;
}>;

export default function Container({
  children,
  className,
  as: Comp = "div",
  ...rest
}: ContainerProps) {
  return (
    <Comp
      className={classNames(
        "max-w-8xl mx-auto px-4 sm:px-6 lg:px-8",
        className,
      )}
      {...rest}
    >
      <div className={"max-w-7xl mx-auto"}>{children}</div>
    </Comp>
  );
}
