import { useTranslations } from "next-intl";
import { useMemo } from "react";

export default function useCallPriceMessage(
  customFeeDescriptionShort: string | null | undefined,
  isFeeVoluntary: boolean,
  categories: { price?: number | null; imagePrice?: number | null }[],
  fee: number,
  currency: string,
) {
  const t = useTranslations("packages.hooks.useCallPriceMessage");
  const paymentMessage = useMemo(() => {
    if (customFeeDescriptionShort) {
      return customFeeDescriptionShort;
    }
    if (isFeeVoluntary) {
      return t("voluntary");
    }
    if (
      categories.some(
        (category) => (category?.price || 0) + (category?.imagePrice || 0) > 0,
      )
    ) {
      return t("variable");
    }
    if (fee > 0) {
      return t("fixed", {
        amount: fee,
        currency: currency,
      });
    }
    return t("free");
  }, [customFeeDescriptionShort, isFeeVoluntary, categories, fee, currency, t]);
  return paymentMessage;
}

useCallPriceMessage.messages = ["packages.hooks.useCallPriceMessage"];
