import { Url } from "next/dist/shared/lib/router/router";
import { MouseEventHandler, PropsWithChildren, forwardRef } from "react";

import { classNames } from "@shared/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type As<P = any> = React.ElementType<P>;

export type ButtonProps = PropsWithChildren<{
  className?: string;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  variant?: "primary" | "secondary" | "default" | "text" | "danger" | "white";
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  inProgress?: boolean;
  /**
   * We use nexts URL type here as its a string and a URL
   * object so that we can compose Link and Button together.
   * the URL object will be serialized with toString() if its not a string.
   * */
  href?: Url;
  type?: "button" | "submit" | "reset";
  as?: As;
}>;

function ProgressSpinner() {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export default forwardRef<unknown, ButtonProps>(function Button(
  {
    className,
    children = "Label",
    size = "base",
    variant = "default",
    disabled,
    onClick,
    inProgress = false,
    as: Comp = "button",
    ...rest
  }: ButtonProps,
  ref,
) {
  return (
    <Comp
      ref={ref}
      className={classNames(
        "focus-visible:ring-primary-500 cursor-pointer inline-flex items-center justify-center border border-transparent font-medium rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 font-display",
        {
          "px-2.5 py-1.5 text-xs": size === "xs",
          "px-3 py-2 text-sm leading-4": size === "sm",
          "px-4 py-2 text-sm": size === "base",
          "px-4 py-2 text-base": size === "lg",
          "px-6 py-3 text-base": size === "xl",
          shadow: variant !== "text" && variant !== "white",
          "text-white bg-primary-600 hover:bg-primary-700":
            variant === "primary",
          "text-primary-700 bg-primary-100 hover:bg-primary-200":
            variant === "secondary",
          "text-gray-700 bg-white hover:bg-gray-50 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:hover:bg-gray-800":
            variant === "default",
          "text-secondary-900 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white px-0":
            variant === "text",
          "text-white bg-red-600 hover:bg-red-700 dark:bg-red-200 dark:text-red-700 dark:hover:bg-red-300":
            variant === "danger",
          "bg-white hover:text-primary-800 text-primary-700":
            variant === "white",
          "opacity-50 cursor-not-allowed": disabled,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled ? true : undefined}
      {...rest}
    >
      {inProgress && <ProgressSpinner />} {children}
    </Comp>
  );
});
