import { Dialog, Transition } from "@headlessui/react";
import classNames from "clsx";
import { useTranslations } from "next-intl";
import { Fragment, PropsWithChildren } from "react";

import { MESSAGE_PREFIX } from "../constants";

type Size = "narrow" | "base" | "wide";

type Props = PropsWithChildren<{
  show: boolean;
  onClose: () => void;
  size?: Size;
}>;

export default function SidebarOverlay({
  show,
  onClose,
  size = "base",
  children,
}: Props) {
  const t = useTranslations(`${MESSAGE_PREFIX}SidebarOverlay`);

  const containerClasses = classNames(
    "pointer-events-auto relative w-screen max-w-[calc(100vw-40px)]",
    {
      "sm:max-w-md": size === "narrow",
      "sm:max-w-xl": size === "base",
      "sm:max-w-2xl": size === "wide",
    },
  );

  const CloseCrossButton = ({ onClose }: Pick<Props, "onClose">) => (
    <button
      type="button"
      className="rounded-md text-gray-300 hover:text-white focus:outline-none"
      onClick={onClose}
    >
      <span className="sr-only">{t("closePanel")}</span>
      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-20"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-900 bg-opacity-[55%] transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full z-20">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={containerClasses}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 flex pt-6 md:pr-6 sm:-ml-10 sm:pr-4">
                    <CloseCrossButton onClose={onClose} />
                  </div>
                </Transition.Child>
                <div className="flex h-full flex-col overflow-y-hidden shadow-xl bg-white">
                  <div className="overflow-y-auto h-full">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SidebarOverlay.messages = [`${MESSAGE_PREFIX}SidebarOverlay`];

SidebarOverlay.Title = Dialog.Title;
