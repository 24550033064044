import classNames from "clsx";
import { useTranslations } from "next-intl";

import { Badge } from "@shared/elements";
import { useAdImpressionTracking } from "@shared/tracking";

import { MESSAGE_PREFIX } from "./constants";

type LoginAdProps = {
  className?: string;
  campaign: string;
  src: string;
  poster?: string;
  type: "video" | "image";
  clickUrl: string;
  impressionUrl: string;
};

export default function LoginAd({
  className,
  src,
  poster,
  type,
  campaign,
  impressionUrl,
  clickUrl,
  ...rest
}: LoginAdProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}LoginAd`);
  const ref = useAdImpressionTracking({
    impressionUrl,
  });

  return (
    <div
      className={classNames(
        "h-full flex flex-col justify-center items-center",
        className,
      )}
      {...rest}
    >
      <div>
        <a
          href={clickUrl}
          className="block aspect-w-1 aspect-h-1 overflow-hidden mb-4 lg:w-[400px] xl:w-[540px]"
          ref={ref}
        >
          {type === "image" ? (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img src={src} className="object-cover" alt={campaign} />
          ) : (
            /* eslint-disable-next-line jsx-a11y/media-has-caption */
            <video autoPlay muted playsInline loop poster={poster}>
              <source src={src} type="video/mp4" />
            </video>
          )}
        </a>
        <div className="flex items-center justify-center mb-3">
          <Badge color="primary" size="xs">
            {t("badge")}
          </Badge>
        </div>
      </div>
    </div>
  );
}

LoginAd.messages = [`${MESSAGE_PREFIX}LoginAd`];
