import classNames from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { HiArrowNarrowRight, HiCreditCard } from "react-icons/hi";
import Imgix from "react-imgix";

import { Button, Container, Text, Tooltip } from "@shared/elements";
import {
  useCallPriceMessage,
  useSubmissionCTAButton,
  useSubmissionStatus,
} from "@shared/hooks";
import { event } from "@shared/tracking";

import {
  TCallPageHeroFields,
  TSubmissionStatus,
} from "../../__generated__/types";
import { MESSAGE_PREFIX } from "../constants";
import Deadline from "../Deadline";
import QuickInfo from "../QuickInfo";
import SidebarOverlay from "../SidebarOverlay";
import StartDate from "../StartDate";
import StatusBadge from "../StatusBadge";
import SubmissionTerms from "../SubmissionTerms";

type Props = {
  callPage: TCallPageHeroFields;
  submissionStatus?: TSubmissionStatus;
};

export default function CallPageHero({ callPage, submissionStatus }: Props) {
  const t = useTranslations(`${MESSAGE_PREFIX}CallPageHero`);
  const status = useSubmissionStatus(
    callPage.call.submissionStartDate,
    callPage.call.submissionEndDate,
    submissionStatus,
  );
  const paymentMessage = useCallPriceMessage(
    callPage.call.customFeeDescriptionShort,
    Boolean(callPage.call.isFeeVoluntary),
    callPage.call.categories,
    callPage.call.fee,
    callPage.call.currency,
  );

  const router = useRouter();
  const callId = router.query.quickInfo as string | undefined;

  const onQuickInfoClose = () => {
    event("quickInfo_closed", {
      call: callPage.call.title.en,
    });
    const { pathname, query } = router;
    delete query.quickInfo;
    router.replace({ pathname, query }, undefined, {
      shallow: true,
    });
  };

  const mainButton = useSubmissionCTAButton({
    status,
    callId: callPage.call.id,
    callSlug: callPage.call.slug,
    useNewSubmissionWizard: Boolean(callPage.call.useNewSubmissionWizard),
  });

  return (
    <Container className="lg:mt-14 xl:mx-0 lg:mx-0 px-0 sm:px-0 md:px-0 lg:px-8">
      <div className="grid grid-rows-1 lg:grid-cols-3 gap-y-8 lg:gap-x-8 lg:gap-y-0 mb-8 lg:mb-14">
        <div className="lg:col-span-2 flex flex-col items-center lg:items-start">
          <div className="block aspect-w-4 aspect-h-3 w-full overflow-hidden lg:max-w-[840px] lg:max-h-[630px]">
            <Imgix
              domain={process.env.NEXT_PUBLIC_IMGIX_URL}
              src={callPage.headerImage as string}
              width={1024}
              height={768}
              imgixParams={{ auto: "format", ar: "4:3", fit: "crop" }}
              htmlAttributes={{
                alt: callPage.headerImageCaption || callPage.call.title.en,
              }}
            />
          </div>
          <Text size="xs" color="muted" className="mt-3">
            {callPage.headerImageCaption}
          </Text>
        </div>
        <div className="w-full flex flex-col items-center lg:justify-center lg:px-0 px-4">
          {callPage.call.logoDefault && (
            <img
              src={callPage.call.logoDefault}
              className="object-contain max-w-[180px] max-h-[80px]"
              alt={callPage.call.title.en}
            />
          )}
          <Text weight="bold" size="3xl" className="text-center mt-5">
            {callPage.call.title.en}
          </Text>
          {status === "UPCOMING" ? (
            <StartDate
              color="warning"
              date={new Date(callPage.call.submissionStartDate)}
              className="mt-5"
            />
          ) : (
            <Deadline
              color="primary"
              date={new Date(callPage.call.submissionEndDate)}
              className="mt-5"
            />
          )}
          <Text className="text-center mt-5">
            {callPage.call.description.en}
          </Text>
          <div className="w-full flex items-center justify-between mt-5">
            <StatusBadge status={status} />
            <div className="flex items-center justify-between">
              <Tooltip
                content={callPage.call.callInfo?.costs}
                className={classNames(
                  callPage.call.callInfo?.costs ?? "hidden",
                )}
                placement="top-end"
              >
                <Text
                  size="xs"
                  color="muted"
                  weight="medium"
                  className="flex group items-center font-semibold"
                >
                  <HiCreditCard className="mr-1" size="1.15rem" />
                  {paymentMessage}
                </Text>
              </Tooltip>
            </div>
          </div>
          <div className="w-full mt-5">
            <div className="flex flex-col gap-4">
              {callPage.call.callInfo?.id && (
                <Button
                  as={Link}
                  href={{
                    pathname: router.pathname,
                    query: { ...router.query, quickInfo: callPage.call.id },
                  }}
                  variant="secondary"
                >
                  {t("canISubmitBtn")}
                </Button>
              )}
              {mainButton && (
                <Button as={Link} variant="primary" href={mainButton.href}>
                  {mainButton.label}
                  <HiArrowNarrowRight className="ml-2" />
                </Button>
              )}
            </div>
            {status === "OPEN" && (
              <Text as="p" size="xs" color="muted" className="flex mt-4">
                <SubmissionTerms />
              </Text>
            )}
          </div>
        </div>
      </div>
      <SidebarOverlay
        show={Boolean(callId)}
        size="wide"
        onClose={onQuickInfoClose}
      >
        {callId && (
          <QuickInfo
            data={callPage.call}
            showFooterLogo={false}
            showMoreInfo={false}
            submissionStatus={submissionStatus}
          />
        )}
      </SidebarOverlay>
    </Container>
  );
}

CallPageHero.messages = [
  `${MESSAGE_PREFIX}CallPageHero`,
  ...Deadline.messages,
  ...StartDate.messages,
  ...useCallPriceMessage.messages,
  ...useSubmissionCTAButton.messages,
  ...SidebarOverlay.messages,
  ...QuickInfo.messages,
  ...SubmissionTerms.messages,
];
