import { Dialog as _Dialog, Transition } from "@headlessui/react";
import { useTranslations } from "next-intl";
import { ComponentProps, Fragment, PropsWithChildren, useRef } from "react";

import { Button, Text } from "@shared/elements";
import { classNames } from "@shared/utils";

import { MESSAGE_PREFIX } from "../constants";

/**
 * Maps the variant of the confirm button to the background color of the icon.
 * @example
 * if confirm button has variant="danger", the icon background will be `bg-red-100`
 */
const iconBackground = {
  primary: "bg-primary-100",
  secondary: "bg-white",
  default: "bg-white",
  text: "bg-white",
  danger: "bg-red-100",
  white: "bg-white",
};

type ButtonConfig = {
  hide?: boolean;
  /**
   * The position of the button. Defaults to "left".
   * On small screens, the confirm button would still show up on top of the cancel button.
   */
  position?: "left" | "right";
} & ComponentProps<typeof Button>;

type DialogProps = PropsWithChildren<{
  title: string;
  description: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  Icon: React.ReactNode;
  confirmButton?: ButtonConfig;
  cancelButton?: ButtonConfig;
  centered?: boolean;
  className?: string;
}>;

const defaultButtonProps: ButtonConfig = {
  hide: false,
  position: "left",
};

export default function Dialog({
  title,
  description,
  open,
  setOpen,
  Icon,
  confirmButton = defaultButtonProps,
  cancelButton = defaultButtonProps,
  centered = false,
  className,
  children,
}: DialogProps) {
  const cancelButtonRef = useRef(null);
  const t = useTranslations(`${MESSAGE_PREFIX}Dialog`);

  return (
    <Transition.Root show={open} as={Fragment}>
      <_Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <_Dialog.Panel
                className={classNames(
                  "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6 max-w-[343px]",
                  {
                    "text-center": centered,
                  },
                  className,
                )}
              >
                <div
                  className={classNames(
                    "flex flex-col sm:flex-row gap-4 sm:items-start",
                    {
                      "sm:items-center sm:flex-col gap-3": centered,
                    },
                  )}
                >
                  <div
                    className={classNames(
                      "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0",
                      iconBackground[confirmButton.variant ?? "danger"],
                    )}
                  >
                    {Icon}
                  </div>
                  <div
                    className={classNames(
                      "flex flex-col gap-3 text-center sm:text-left",
                      {
                        "sm:text-center": centered,
                      },
                    )}
                  >
                    <div>
                      <_Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        <Text size="lg" weight="medium">
                          {title}
                        </Text>
                      </_Dialog.Title>
                      <div className="mt-2">
                        <Text size="sm" color="muted" className="leading-5">
                          {description}
                        </Text>
                      </div>
                    </div>
                    {children && (
                      <div
                        className={classNames("flex justify-center", {
                          "sm:justify-start": !centered,
                        })}
                      >
                        {children}
                      </div>
                    )}
                    <div
                      className={classNames(
                        "flex flex-col sm:flex-row gap-3 mt-2 sm:mt-3",
                        {
                          "items-center justify-center": centered,
                        },
                      )}
                    >
                      {!confirmButton.hide && (
                        <Button
                          type="button"
                          variant="danger"
                          {...confirmButton}
                          className={classNames(
                            "text-base leading-normal sm:text-sm sm:leading-5",
                            {
                              "sm:order-last":
                                confirmButton.position === "right",
                            },
                            confirmButton.className,
                          )}
                          onClick={(e) => {
                            // Call the onClick callback of the confirm button
                            confirmButton.onClick?.(e);
                            // Close the dialog
                            setOpen(false);
                          }}
                        >
                          {confirmButton.children ??
                            t("confirmButtonDefaultText")}
                        </Button>
                      )}
                      {!cancelButton.hide && (
                        <Button
                          type="button"
                          variant="default"
                          {...cancelButton}
                          className={classNames(
                            "text-base leading-normal sm:text-sm sm:leading-5",
                            "ring-1 ring-gray-300",
                            cancelButton.className,
                          )}
                          onClick={(e) => {
                            // Call the onClick callback of the cancel button
                            cancelButton.onClick?.(e);
                            // Close the dialog
                            setOpen(false);
                          }}
                        >
                          {cancelButton.children ??
                            t("cancelButtonDefaultText")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </_Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </_Dialog>
    </Transition.Root>
  );
}

Dialog.messages = [`${MESSAGE_PREFIX}Dialog`];
