import { LinkProps } from "next/link";
import { PropsWithChildren } from "react";

import ActiveLink from "../ActiveLink";

type NavItemProps = PropsWithChildren<LinkProps>;

export default function NavbarItemMobile({ children, ...rest }: NavItemProps) {
  return (
    <ActiveLink
      activeClassName="!border-primary-500 !text-primary-700 bg-primary-50"
      {...rest}
    >
      <span className="border-transparent text-gray-500  block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
        {children}
      </span>
    </ActiveLink>
  );
}
