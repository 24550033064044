import classNames from "clsx";
import { ReactNode, forwardRef } from "react";

import Label from "./Label";
import Text from "./Text";

export type CheckboxProps = {
  error?: ReactNode;
  hint?: ReactNode;
  label: ReactNode;
  name: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

export default forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  {
    className,
    disabled,
    error,
    hint,
    id,
    label,
    name,
    required = false,
    ...rest
  },
  ref,
) {
  return (
    <div className={classNames(className)} {...rest}>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            ref={ref}
            type="checkbox"
            name={name}
            id={id || name}
            aria-describedby={hint ? `${id || name}-description` : undefined}
            className={classNames(
              {
                "focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded":
                  error === undefined,
                "opacity-50 cursor-not-allowed": disabled,
                "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500":
                  error,
              },
              "shadow-sm block rounded",
            )}
            disabled={disabled ? true : undefined}
            required={required}
            {...rest}
          />
        </div>
        <div className="ml-3 text-sm">
          <Label htmlFor={id || name} className={classNames("")}>
            {label}
          </Label>
        </div>
      </div>
      {!error && hint ? (
        <Text
          as="p"
          size="sm"
          color="muted"
          className="mt-2"
          id={`${id || name}-description`}
        >
          {hint}
        </Text>
      ) : null}
      {error ? (
        <Text
          as="p"
          size="sm"
          color="danger"
          className="mt-2"
          id={`${id || name}-description`}
        >
          {error}
        </Text>
      ) : null}
    </div>
  );
});
