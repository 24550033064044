import Cookies from "js-cookie";

import { COOKIE_CONSENT_COOKIE_NAME, COOKIE_VALUES } from "./constants";

export function areCookiesAccepted() {
  return Cookies.get(COOKIE_CONSENT_COOKIE_NAME) === COOKIE_VALUES.ALL;
}

export function acceptAllCookies() {
  gtag("consent", "update", {
    ad_storage: "granted",
    analytics_storage: "granted",
  });
}

export function acceptFunctionalCookies() {
  gtag("consent", "update", {
    ad_storage: "denied",
    analytics_storage: "denied",
  });
}
