import classNames from "clsx";
import { PropsWithChildren } from "react";

import Text, { TextProps } from "./Text";

export type BadgeProps = PropsWithChildren<
  {
    className?: string;
    color:
      | "green"
      | "yellow"
      | "red"
      | "primary"
      | "secondary"
      | "lime"
      | "blue"
      | "custom";
  } & Omit<TextProps, "color">
>;

export default function Badge({
  children,
  className,
  color = "secondary",
  size = "xs",
  ...rest
}: BadgeProps) {
  const classes = classNames(
    {
      "dark:bg-green-800 dark:text-green-100 bg-green-100 text-green-800":
        color === "green",
      "dark:bg-red-800 dark:text-red-100 bg-red-100 text-red-800":
        color === "red",
      "dark:bg-amber-800 dark:text-amber-100 bg-amber-100 text-amber-800":
        color === "yellow",
      "dark:bg-primary-800 dark:text-primary-100 bg-primary-100 text-primary-800":
        color === "primary",
      "dark:bg-secondary-800 dark:text-secondary-100 bg-secondary-100 text-secondary-800":
        color === "secondary",
      "dark:bg-lime-800 dark:text-lime-100 bg-lime-100 text-lime-800":
        color === "lime",
      "dark:bg-blue-800 dark:text-blue-100 bg-blue-100 text-blue-800":
        color === "blue",
    },
    "rounded-md px-2 py-1",
    className,
  );
  return (
    <Text
      className={classes}
      weight="medium"
      size={size}
      {...rest}
      color="custom"
      as="span"
    >
      {children}
    </Text>
  );
}
