import { useTranslations } from "next-intl";
import { Fragment } from "react";

import { Badge } from "@shared/elements";
import { type useSubmissionStatus } from "@shared/hooks";

import { MESSAGE_PREFIX } from "../constants";

type Props = {
  status: ReturnType<typeof useSubmissionStatus>;
  "data-testid"?: string;
};

export default function StatusBadge({ status, "data-testid": testId }: Props) {
  const t = useTranslations(`${MESSAGE_PREFIX}StatusBadge`);

  const color = {
    DRAFT: "yellow",
    IN_EDIT: "yellow",
    PAYMENT_IN_PROGRESS: "yellow",
    SUBMITTED: "lime",
    CLOSED: "red",
    UPCOMING: "blue",
    OPEN: "green",
  } as const;

  return (
    <Fragment>
      {status === "LOADING" ? (
        <div className="h-6 w-20 rounded-md bg-gray-100" />
      ) : (
        <Badge color={color[status]} size="xs" data-testid={testId}>
          {t(`callStatus.${status}`)}
        </Badge>
      )}
    </Fragment>
  );
}
StatusBadge.messages = [`${MESSAGE_PREFIX}StatusBadge`];
