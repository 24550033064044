// copied from tailwinds default breakpoints:
// https://tailwindcss.com/docs/responsive-design#overview
export const breakpoints = [
  "0" /* mobile */,
  "640" /* sm */,
  "768" /* md */,
  "1024" /* lg */,
  "1280" /* xl */,
  "1536" /* 2xl */,
];

export function getCurrentBreakpointIndex() {
  return breakpoints.reduce((value, breakpoint, index) => {
    if (window.matchMedia("(min-width: " + breakpoint + "px)").matches) {
      return index;
    }
    return value;
  }, 0);
}
