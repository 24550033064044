import classNames from "clsx";
import React from "react";

import { Text } from "@shared/elements";
import {
  filterNullAndUndefinedValuesFromObject,
  toQueryString,
} from "@shared/utils";

type Props = {
  src: string;
  caption: string;
  description: string;
  imageWidth?: number;
  imageHeight?: number;
  containerClasses?: string;
  fit?: string;
  maxW?: number;
  maxH?: number;
};

export default function ImageBlock({
  src,
  caption,
  description,
  imageWidth,
  imageHeight,
  containerClasses,
  fit = "max",
  maxW,
  maxH,
}: Props) {
  const params = {
    w: imageWidth,
    h: imageHeight,
    fit: fit,
    "max-w": maxW,
    "max-h": maxH,
  };
  return (
    <div
      className={classNames(
        "flex flex-col items-center lg:items-start",
        containerClasses,
      )}
    >
      <div
        className={`w-full flex flex-col items-center overflow-hidden px-4 lg:px-0`}
      >
        <img
          src={`https://${
            process.env.NEXT_PUBLIC_IMGIX_URL
          }${src}?${toQueryString(
            filterNullAndUndefinedValuesFromObject(params),
          )}`}
          alt={caption}
        />
      </div>
      <div className="w-full flex flex-col items-start px-4 lg:px-0">
        <Text size="xs" className="mt-2">
          {caption}
        </Text>
        <Text size="xs" color="muted" className="mt-1">
          {description}
        </Text>
      </div>
    </div>
  );
}
