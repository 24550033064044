import classNames from "clsx";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { Container } from "@shared/elements";
import { useIsomorphicLayoutEffect } from "@shared/hooks";

import MenuItem from "./MenuItem";
import MenuItemMobile from "./MenuItemMobile";
import NavbarItem from "./NavbarItem";
import NavbarItemMobile from "./NavbarItemMobile";


type NavbarProps = PropsWithChildren<{
  className?: string;
}>;

export default function Navbar({ children, className, ...rest }: NavbarProps) {
  return (
    <Container>
      <div
        className={classNames("flex justify-between h-16", className)}
        {...rest}
      >
        {children}
      </div>
    </Container>
  );
}

function NavbarContainer({
  children,
  className,
  fixed,
  sticky,
  onStickyChange,
}: PropsWithChildren<{
  className?: string;
  fixed?: boolean;
  sticky?: boolean;
  onStickyChange?: (isSticky: boolean) => void;
}>) {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const placeholder = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (!sticky) return;
    const navbarOffsetTop = placeholder.current?.offsetTop || 0;
    const scrollHandler = () => {
      if (!isSticky && window.scrollY >= navbarOffsetTop) {
        setIsSticky(true);
      } else if (isSticky && window.scrollY < navbarOffsetTop) {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [isSticky, sticky]);

  useEffect(() => {
    if (onStickyChange) {
      onStickyChange(isSticky);
    }
  }, [isSticky, onStickyChange]);

  return (
    <>
      <div
        ref={placeholder}
        className={classNames(isSticky || fixed ? "h-[64px]" : "h-0")}
      />
      <nav
        className={classNames(
          "bg-white w-full z-10",
          // Only show shadow as soon as the navbar is sticky or fixed
          (fixed || isSticky) && "fixed top-0 left-0 right-0 shadow",
          // Switch from border to shadow when sticky
          sticky && !isSticky && "border-b border-gray-200",
          className,
        )}
      >
        {children}
      </nav>
    </>
  );
}

Navbar.Container = NavbarContainer;
Navbar.Item = NavbarItem;
Navbar.ItemMobile = NavbarItemMobile;
Navbar.MenuItem = MenuItem;
Navbar.MenuItemMobile = MenuItemMobile;
