import { useTranslations } from "next-intl";

import { MESSAGE_PREFIX } from "../constants";

export default function SubmissionTerms() {
  const t = useTranslations(`${MESSAGE_PREFIX}SubmissionTerms`);
  return <>{t("terms")}</>;
}

SubmissionTerms.messages = [`${MESSAGE_PREFIX}SubmissionTerms`];
