import classNames from "clsx";
import Link from "next/link";
import { MouseEventHandler, PropsWithChildren } from "react";

type MenuItemProps = PropsWithChildren<{
  className?: string;
  active: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}>;

export default function MenuItem({
  className,
  children,
  href,
  onClick,
  active = false,
  ...rest
}: MenuItemProps) {
  const classes = classNames(
    active && "bg-gray-100",
    "px-4 py-2 text-sm text-gray-700 flex items-center cursor-pointer",
    className,
  );
  if (!href) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <a onClick={onClick} className={classes} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link href={href} className={classes} {...rest}>
      {children}
    </Link>
  );
}
