import { TSubmissionCategory } from "@shared/types";

export function sortedGroupByPrice(list: number[] = []): number[][] {
  if (list.length === 0) {
    return [];
  }

  return list.reduce<number[][]>((groupList, item) => {
    const lastItem = groupList[groupList.length - 1];

    if (lastItem?.[0] === item) {
      groupList[groupList.length - 1] = [item, (lastItem[1] ?? 0) + 1];
      return groupList;
    }

    groupList.push([item, 1]);
    return groupList;
  }, []);
}

export function isNoGraduationPerProject(
  category: Partial<TSubmissionCategory>,
) {
  return Boolean(
    category.price &&
      (!category.priceGraduation ||
        category.priceGraduation.length === 0 ||
        category.priceGraduation.length === 1),
  );
}

export function isGraduationPerProject(category: Partial<TSubmissionCategory>) {
  return Boolean(category.price && !isNoGraduationPerProject(category));
}

export function isNoGraduationPerImage(category: Partial<TSubmissionCategory>) {
  return Boolean(
    category.imagePrice &&
      (!category.imagePriceGraduation ||
        category.imagePriceGraduation.length === 0 ||
        category.imagePriceGraduation.length === 1),
  );
}

export function isGraduationPerImage(category: Partial<TSubmissionCategory>) {
  return Boolean(category.imagePrice && !isNoGraduationPerImage(category));
}
