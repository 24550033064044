import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import { useInViewEffect } from "react-hook-inview";

export function useAdImpressionTracking({
  visibleThreshold = 0.5,
  impressionUrl,
}: {
  visibleThreshold?: number;
  impressionUrl: string;
}) {
  const onView = useCallback(() => {
    fetch(impressionUrl).catch((error) => {
      Sentry.captureException(error);
    });
  }, [impressionUrl]);

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        onView();
      }
    },
    { threshold: visibleThreshold },
    [onView],
  );
  return ref;
}
