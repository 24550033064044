import { DocumentNode } from "graphql";
import { GraphQLClient } from "graphql-request";
import useSWR, { SWRConfiguration } from "swr";

export async function fetcher(
  queryObject: string,
  requestHeaders?: HeadersInit,
): Promise<any> {
  const { query, variables } = JSON.parse(queryObject);
  // variables must either be a non-empty object or undefined (falsy)
  if ((variables && JSON.stringify(variables) !== "{}") || !variables) {
    const client = new GraphQLClient(
      `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
      {
        credentials: "include",
      },
    );
    const data = await client.request(query, variables, requestHeaders);
    return data;
  }
  return null;
}

export function useGraphSWR<T>(
  query: string | DocumentNode,
  variables: Record<string, any>,
  config?: Partial<SWRConfiguration>,
) {
  // We need to stringify the input, otherwise SWR seems
  // not to be able to cache the query.
  const swrResponse = useSWR<T>(JSON.stringify({ query, variables }), fetcher, {
    focusThrottleInterval: 20000,
    ...config,
  });
  return swrResponse;
}

// This version of the hook does not revalidate the data
// after it has been fetched once.
export function useGraphSWRImmutable<T>(
  query: string | DocumentNode,
  variables: Record<string, any>,
) {
  // We need to stringify the input, otherwise SWR seems
  // not to be able to cache the query.
  const { data } = useSWR<T>(JSON.stringify({ query, variables }), fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });
  return data || ({} as T);
}
