
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useTranslations } from "next-intl";
import { Fragment, useState } from "react";
import {
  HiCog as SettingsIcon,
  HiCollection,
  HiExternalLink as ExternalLinkIcon,
  HiFire,
  HiLogout as LogoutIcon,
  HiOutlineBriefcase,
  HiOutlineCog,
  HiOutlineCollection,
  HiOutlineEye,
  HiOutlineFire,
  HiOutlineLogout,
  HiOutlineQuestionMarkCircle,
  HiOutlineUserCircle,
  HiQuestionMarkCircle as SupportIcon,
  HiUserCircle as UserIcon,
  HiX as XIcon,
} from "react-icons/hi";
import { useIntercom } from "react-use-intercom";

import { UseUserType } from "@shared/auth";
import { urls } from "@shared/constants";
import { Avatar, PicterLogo, Text } from "@shared/elements";

import { MESSAGE_PREFIX } from "../constants";
import Navbar from "../Navbar";
import ProductSwitcher from "../ProductSwitcher";
import SidebarOverlay from "../SidebarOverlay";

type PicterMenuProps = {
  user: UseUserType;
};

export default function PicterMenu({ user }: PicterMenuProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}PicterMenu`);
  const { show: showIntercom } = useIntercom();
  const [showPicterAppsOverlay, setShowPicterAppsOverlay] = useState(false);

  return (
    <>
      <div className="md:absolute md:z-10 md:right-5 md:top-5 hidden md:block">
        <Menu as="span">
          <Menu.Button className="rounded-sm flex items-center text-sm focus:outline-none">
            <Avatar className="" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
              <div className="px-4 py-3">
                <PicterLogo className="h-[32px] w-auto" />
              </div>
              <div className="pb-1">
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem href={urls.index} active={active}>
                      <HiFire
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("opportunities")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
              </div>
              <div className="pb-1">
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem href={urls.mySubmissions} active={active}>
                      <HiCollection
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("mySubmissions")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem
                      href="https://account.picter.com/profile"
                      active={active}
                    >
                      <UserIcon
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("myProfile")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem
                      href="https://account.picter.com/profile"
                      active={active}
                    >
                      <SettingsIcon
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("settings")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem onClick={showIntercom} active={active}>
                      <SupportIcon
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("support")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem
                      onClick={() => setShowPicterAppsOverlay(true)}
                      active={active}
                    >
                      <ExternalLinkIcon
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("moreApps")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
              </div>
              <div className="pt-1">
                <Menu.Item>
                  {({ active }) => (
                    <Navbar.MenuItem onClick={user.logout} active={active}>
                      <LogoutIcon
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("logout")}
                    </Navbar.MenuItem>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="flex items-center md:hidden w-screen">
        <Disclosure as={Navbar.Container}>
          {({ open }) => (
            <>
              <Disclosure.Button className="absolute right-4 top-5 z-10 rounded-sm flex items-center text-sm focus:outline-none">
                {open ? (
                  <XIcon
                    className="block h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <Avatar className="" />
                )}
              </Disclosure.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Disclosure.Panel className="md:hidden w-screen">
                  <div className="p-4 border-b-[1px] border-gray-200">
                    <PicterLogo className="h-[32px] w-auto" />
                  </div>
                  <div className="mt-2 mb-1">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      href={urls.index}
                    >
                      <HiOutlineFire
                        className="w-5 h-5 mr-3"
                        aria-hidden="true"
                      />
                      <Text weight="medium" color="muted">
                        {t("opportunities")}
                      </Text>
                    </Disclosure.Button>
                  </div>
                  <div className="mb-1">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      href={urls.index}
                    >
                      <HiOutlineEye
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      <Text weight="medium" color="muted">
                        {t("curate")}
                      </Text>
                    </Disclosure.Button>
                  </div>
                  <div className="pb-2 border-b-[1px] border-gray-200">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      href={urls.index}
                    >
                      <HiOutlineBriefcase
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      <Text weight="medium" color="muted">
                        {t("workspace")}
                      </Text>
                    </Disclosure.Button>
                  </div>
                  <div className="mb-1 flex items-center px-4 py-3">
                    <Avatar size="base" name={user.data?.publicName} />
                    <div className="text-left ml-3">
                      <Text as="div" size="sm" className="-mb-1">
                        {user.data?.publicName}
                      </Text>
                      <Text as="sub" size="sm" color="muted">
                        {user.data?.email}
                      </Text>
                    </div>
                  </div>
                  <div className="mb-1">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      href={urls.mySubmissions}
                    >
                      <HiOutlineCollection
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("mySubmissions")}
                    </Disclosure.Button>
                  </div>
                  <div className="mb-1">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      href="https://account.picter.com/profile"
                    >
                      <HiOutlineUserCircle
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("myProfile")}
                    </Disclosure.Button>
                  </div>
                  <div className="mb-1">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      href="https://account.picter.com/profile"
                    >
                      <HiOutlineCog
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("settings")}
                    </Disclosure.Button>
                  </div>
                  <div className="pb-2 border-b-[1px] border-gray-200">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      onClick={showIntercom}
                    >
                      <HiOutlineQuestionMarkCircle
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("support")}
                    </Disclosure.Button>
                  </div>
                  <div className="py-2 border-b-[1px] border-gray-200">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      onClick={user.logout}
                    >
                      <HiOutlineLogout
                        className="w-5 h-5 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("logout")}
                    </Disclosure.Button>
                  </div>
                  <div className="py-2">
                    <Disclosure.Button
                      as={Navbar.MenuItemMobile}
                      onClick={() => setShowPicterAppsOverlay(true)}
                    >
                      <ExternalLinkIcon
                        className="w-6 h-6 mr-3 text-gray-400"
                        aria-hidden="true"
                      />
                      {t("moreApps")}
                    </Disclosure.Button>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
      <SidebarOverlay
        show={showPicterAppsOverlay}
        size="narrow"
        onClose={() => {
          setShowPicterAppsOverlay(false);
        }}
      >
        <>
          <div className="px-4 sm:px-6 py-6 bg-gray-50">
            <div className="flex items-start justify-between">
              <Text as={SidebarOverlay.Title} size="lg">
                {t("moreAppsTitle")}
              </Text>
            </div>
          </div>
          <ProductSwitcher className="p-4 sm:p-6" />
        </>
      </SidebarOverlay>
    </>
  );
}

PicterMenu.messages = [
  `${MESSAGE_PREFIX}PicterMenu`,
  ...SidebarOverlay.messages,
  ...ProductSwitcher.messages,
];
