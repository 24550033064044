import Link from "next/link";
import { useTranslations } from "next-intl";

import { urls } from "@shared/constants";
import { Text } from "@shared/elements";

import PicterIcon from "./PicterIcon";
import { MESSAGE_PREFIX } from "../../src/constants";

export default function PoweredBy() {
  const t = useTranslations(`${MESSAGE_PREFIX}PoweredBy`);
  const title = t("title");

  return (
    <div className="inline-block">
      <Link
        className="flex items-center gap-2 py-1 shadow-sm border border-gray-200 px-3 rounded hover:bg-primary-50 transition ease-in-out"
        href={urls.index}
        passHref
        target="_blank"
        rel="reopener noreferrer"
      >
        <PicterIcon />
        <Text as="span" size="sm" weight="medium">
          {title}
        </Text>
      </Link>
    </div>
  );
}

PoweredBy.messages = [`${MESSAGE_PREFIX}PoweredBy`];
