import clsx, { type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * This is a wrapper around `clsx` that resolves conflicting
 * classes using the `tailwind-merge` utility such that last
 * conflicting class wins.
 */
export function classNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
