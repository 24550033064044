import { GA_TRACKING_ID } from "./constants";

export function TrackingScriptTags() {
  if (!GA_TRACKING_ID) {
    throw new Error(
      "Environment variable NEXT_PUBLIC_GA_ID is not set. Please set it to a valid Google Analytics Tracking ID.",
    );
  }
  return (
    <>
      <script
        key="gtag-lib"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <script
        key="gtag-init"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            // Default ad_storage to 'denied'.
            gtag('consent', 'default', {
              ad_storage: 'denied',
              analytics_storage: 'denied',
              wait_for_update: 500,
            });
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              anonymize_ip: true,
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
}
