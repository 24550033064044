import classNames from "clsx";
import { PropsWithChildren } from "react";

import Text from "./Text";

type LabelProps = PropsWithChildren<{
  required?: boolean;
  className?: string;
  htmlFor: string;
}>;

export default function Label({
  className,
  children,
  required,
  ...rest
}: LabelProps) {
  return (
    <Text
      as="label"
      size="sm"
      color="custom"
      className={classNames("block text-gray-700 leading-6", className)}
      weight="medium"
      aria-label={required ? "required" : undefined}
      {...rest}
    >
      {children}
      {required && <span className="text-red-600 font-bold ml-1">*</span>}
    </Text>
  );
}
