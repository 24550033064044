import classNames from "clsx";
import Link, { LinkProps } from "next/link";
import { MouseEventHandler, PropsWithChildren } from "react";

import Text from "./Text";


export type FooterLinkProps = PropsWithChildren<
  {
    className?: string;
    color?: "default" | "muted";
    onClick?: MouseEventHandler;
  } & LinkProps
>;

export default function FooterLink({
  className,
  children,
  color = "default",
  ...rest
}: FooterLinkProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { as, ...linkProps } = rest;
  return (
    <Text
      as={Link}
      passHref
      className={classNames(color === "muted" && "text-gray-400", className)}
      color={color === "default" ? "muted" : "custom"}
      {...linkProps}
    >
      {children}
    </Text>
  );
}
