import classNames from "clsx";
import { useTranslations, useFormatter } from "next-intl";
import { useMemo } from "react";
import { HiClock, HiCalendar } from "react-icons/hi";

import { Text, TextProps } from "@shared/elements";

import { MESSAGE_PREFIX } from "../constants";
import TinyTimeRelative, { TimeUnit } from "../TinyTimeRelative";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 364;

type StartDateProps = {
  date: Date;
  className?: string;
  color?: TextProps["color"];
};

export default function StartDate({ date, className, color }: StartDateProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}StartDate`);
  const format = useFormatter();
  const timeUnits: TimeUnit[] = useMemo(
    () => [
      { unit: t("now") as string, valueMs: 20 * SECOND },
      {
        unit: (value) => t("seconds", { value }) as string,
        valueMs: SECOND,
        threshold: 45,
      },
      {
        unit: (value) => t("minutes", { value }) as string,
        valueMs: MINUTE,
        threshold: 50,
      },
      {
        unit: (value) => t("hours", { value }) as string,
        valueMs: HOUR,
        threshold: 47,
      },
      {
        unit: (value) => t("days", { value }) as string,
        valueMs: DAY,
        threshold: 8,
      },
      {
        unit: (value) => t("weeks", { value }) as string,
        valueMs: 7 * DAY,
        threshold: 4,
      },
      {
        unit: (value) => t("months", { value }) as string,
        valueMs: MONTH,
        threshold: 12,
      },
      {
        unit: (value) => t("years", { value }) as string,
        valueMs: YEAR,
      },
    ],
    [t],
  );

  const isPastDate = (date: Date) => date < new Date(Date.now());

  return (
    <Text
      size="sm"
      weight="medium"
      color={color || "muted"}
      className={classNames("flex group items-center", className)}
    >
      <time
        dateTime={date.toISOString()}
        data-testid="date-relative"
        className={classNames("group-hover:hidden inline-flex items-center", {
          hidden: isPastDate(date),
        })}
      >
        <HiClock className="mr-1 inline-block" size="1rem" />
        <TinyTimeRelative to={date} timeUnits={timeUnits} />
      </time>
      <time
        dateTime={date.toISOString()}
        data-testid="date-absolute"
        className={classNames(
          "group-hover:inline-flex items-center",
          isPastDate(date) ? "inline-flex" : "hidden",
        )}
      >
        <HiCalendar className="mr-1 inline-block" size="1rem" />
        {format.dateTime(date, {
          dateStyle: "medium",
          timeStyle: "short",
        })}
      </time>
    </Text>
  );
}

StartDate.messages = [`${MESSAGE_PREFIX}StartDate`];
