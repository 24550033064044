import { LinkProps } from "next/link";
import { PropsWithChildren } from "react";

import { Text } from "@shared/elements";

import ActiveLink from "../ActiveLink";

type NavItemProps = PropsWithChildren<LinkProps>;

export default function NavbarItem({ children, ...rest }: NavItemProps) {
  return (
    <ActiveLink activeClassName="!border-primary-500 !text-gray-900" {...rest}>
      <Text
        color="muted"
        size="sm"
        weight="medium"
        className="h-full border-transparent hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2"
      >
        {children}
      </Text>
    </ActiveLink>
  );
}
