import { Popover, Transition } from "@headlessui/react";
import { Placement } from "@popperjs/core";
import classNames from "clsx";
import {
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useState,
  useRef,
  HtmlHTMLAttributes,
} from "react";
import { usePopper } from "react-popper";

export type TooltipProps = PropsWithChildren<{
  content?: ReactNode;
  containerClassName?: string;
  className?: string;
  placement?: Placement;
  skidding?: number;
  distance?: number;
}>;

export default function Tooltip({
  content,
  children,
  containerClassName,
  className,
  placement = "top-start",
  skidding = 0,
  distance = 0,
  ...rest
}: TooltipProps) {
  const [show, setShow] = useState(false);
  const referenceElementRef = useRef(null);
  const popperElementRef = useRef(null);

  const { styles, attributes } = usePopper(
    referenceElementRef.current,
    popperElementRef.current,
    {
      placement,
      modifiers: [
        {
          name: "offset",
          options: { offset: [skidding, distance] },
        },
      ],
    },
  );

  function handleMouseEnter() {
    setShow(true);
  }

  function handleMouseLeave() {
    setShow(false);
  }

  return (
    <Popover className={classNames("", containerClassName)} {...rest}>
      <>
        {isValidElement(children) ? (
          cloneElement(children, {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            ref: referenceElementRef,
          } as HtmlHTMLAttributes<HTMLSpanElement>)
        ) : (
          <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={referenceElementRef}
          >
            {children}
          </span>
        )}
        <Transition
          show={show}
          enter="transition ease-in duration-200"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-out duration-200"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <Popover.Panel
            className={classNames(
              "text-sm text-white bg-gray-800 rounded-md px-3 py-2 w-max max-w-xs",
              className,
            )}
            ref={popperElementRef}
            style={styles.popper}
            {...attributes.popper}
          >
            {content}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}
