import classNames from "clsx";
import Link from "next/link";
import { useFormatter, useTranslations } from "next-intl";
import { Fragment, useEffect, useState } from "react";
import {
  HiArrowNarrowRight,
  HiCalendar,
  HiCheck,
  HiExternalLink,
  HiInformationCircle,
} from "react-icons/hi";
import ReactMarkdown from "react-markdown";

import { urls } from "@shared/constants";
import {
  Alert,
  Button,
  PicterContestsLogo,
  Text,
  Tooltip,
} from "@shared/elements";
import {
  useLocalStorage,
  useSubmissionCTAButton,
  useSubmissionStatus,
} from "@shared/hooks";

import { TQuickInfoFields, TSubmissionStatus } from "../../__generated__/types";
import { MESSAGE_PREFIX } from "../constants";
import { A, OL, UL } from "../MarkdownElements";
import StatusBadge from "../StatusBadge";
import SubmissionTerms from "../SubmissionTerms";

const QUICK_INFO_ALERT_LOCAL_STORAGE_KEY = "picter-quick-info-pages-alert";

function Question({ question, answer }: { question: string; answer: string }) {
  return (
    <Fragment key={question}>
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="font-display text-base font-semibold md:text-sm md:font-medium text-gray-900">
          {question}
        </dt>
        <dd className="font-display mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <ReactMarkdown
            disallowedElements={["script", "style", "input"]}
            components={{
              ul: UL,
              ol: OL,
              a: A,
            }}
            className="whitespace-pre-line"
          >
            {answer}
          </ReactMarkdown>
        </dd>
      </div>
    </Fragment>
  );
}

type Props = {
  data: TQuickInfoFields;
  showFooterLogo?: boolean;
  showMoreInfo?: boolean;
  submissionStatus?: TSubmissionStatus | "LOADING";
};

export default function QuickInfo({
  data,
  showFooterLogo = true,
  showMoreInfo = true,
  submissionStatus,
}: Props) {
  const t = useTranslations(`${MESSAGE_PREFIX}QuickInfo`);
  const format = useFormatter();
  const [showAlert, setShowAlert] = useLocalStorage(
    QUICK_INFO_ALERT_LOCAL_STORAGE_KEY,
    true,
  );
  // Following is the fix for rendering component on Client only while using SSR
  // https://github.com/vercel/next.js/discussions/17443
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const isItExternalLink =
    data.website && !/picter.com/i.test(new URL(data.website).host);

  const status = useSubmissionStatus(
    data.submissionStartDate,
    data.submissionEndDate,
    submissionStatus,
  );

  const mainButton = useSubmissionCTAButton({
    status,
    callId: data.id,
    callSlug: data.slug,
    useNewSubmissionWizard: Boolean(data.useNewSubmissionWizard),
  });

  const moreInfoLink = data.website;

  return (
    <div className="w-full h-full flex flex-col divide-y divide-gray-200 items-center">
      <div className="w-full flex flex-1 flex-grow flex-col overflow-y-auto py-6 relative items-center">
        <div className="relative px-4 sm:px-6 w-full max-w-2xl">
          {showAlert && mounted && (
            <Alert
              color="primary"
              Icon={HiInformationCircle}
              className="py-4 mb-6"
              contentClassName="flex flex-grow"
            >
              <div className="flex flex-col justify-start items-start flex-wrap lg:flex-row lg:items-start lg:flex-nowrap w-full flex-1 lg:justify-between">
                <Text
                  as="p"
                  size="sm"
                  weight="medium"
                  color="current"
                  className="self-start lg:flex-grow-0"
                >
                  {t("alert")}
                </Text>
                <Button
                  variant="text"
                  onClick={() => setShowAlert(false)}
                  className="mt-1 lg:mt-0 !py-0 lg:ml-3 min-w-max"
                >
                  <HiCheck className="!text-primary-700" />
                  <Text
                    as="span"
                    size="sm"
                    weight="medium"
                    className="!text-primary-700 ml-1"
                  >
                    {t("alertBtn")}
                  </Text>
                </Button>
              </div>
            </Alert>
          )}
          <div className="flex items-center justify-between mb-2">
            <StatusBadge status={status} data-testid="status" />
          </div>
          <Text as="h2" size="2xl" weight="bold" className="mb-3">
            {data.title.en}
          </Text>
          <Text as="p" size="sm" weight="medium" color="muted">
            <time
              dateTime={new Date(data.submissionEndDate).toISOString()}
              data-testid="date-absolute"
              className="flex items-center"
            >
              <HiCalendar
                className="mr-1 inline-block text-gray-400"
                size="1.15rem"
              />
              {format.dateTime(new Date(data.submissionEndDate), {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </time>
          </Text>
          <div className="mt-5 py-5 sm:p-0">
            {data.callInfo && (
              <dl className="sm:divide-y sm:divide-gray-200">
                <Question
                  question={t("whoCanSubmit")}
                  answer={data.callInfo?.whoCanSubmit || ""}
                />
                <Question
                  question={t("theme")}
                  answer={data.callInfo?.theme || ""}
                />
                <Question
                  question={t("whatToSubmit")}
                  answer={data.callInfo?.whatToSubmit || ""}
                />
                <Question
                  question={t("whatToWin")}
                  answer={data.callInfo?.whatToWin || ""}
                />
                <Question
                  question={t("costs")}
                  answer={data.callInfo?.costs || ""}
                />
                <Question
                  question={t("host")}
                  answer={data.callInfo?.host || ""}
                />
              </dl>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          { "md:divide-x": showFooterLogo },
          "sticky bottom-0 w-full bg-white flex justify-between flex-col-reverse md:flex-row",
        )}
      >
        {showFooterLogo && (
          <div className="pl-4 pr-4 sm:pl-6 sm:pr-6 py-5 flex items-center justify-center border-t border-gray-200 md:border-0">
            <Tooltip
              content={t("logoTooltip")}
              containerClassName="flex items-center"
              skidding={8}
              distance={8}
            >
              <div>
                <Link href={urls.index}>
                  <PicterContestsLogo className="h-7 sm:h-8 w-auto" />
                </Link>
              </div>
            </Tooltip>
          </div>
        )}
        <div className="lg:pl-0 pl-4 pr-4 sm:pl-6 sm:pr-6 py-5 flex-grow">
          <div className="flex flex-shrink-0 justify-end flex-col gap-4 sm:flex-row">
            {showMoreInfo && moreInfoLink && (
              <Button as={Link} variant="secondary" href={moreInfoLink}>
                {t("moreInfoBtn")}
                {isItExternalLink && (
                  <HiExternalLink className="ml-2 text-primary-500" />
                )}
              </Button>
            )}
            {mainButton && (
              <Button as={Link} variant="primary" href={mainButton.href}>
                {mainButton.label}
                <HiArrowNarrowRight className="ml-2" />
              </Button>
            )}
          </div>
          {status === "OPEN" && (
            <Text
              as="p"
              size="xs"
              color="muted"
              className="flex justify-end sm:text-right mt-3"
            >
              <SubmissionTerms />
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}

QuickInfo.messages = [
  `${MESSAGE_PREFIX}QuickInfo`,
  ...StatusBadge.messages,
  ...SubmissionTerms.messages,
  ...useSubmissionCTAButton.messages,
];
