import classNames from "clsx";

type PicterLogoProps = {
  className?: string;
  iconClassName?: string;
  logoClassName?: string;
};

export default function PicterLogo({
  className,
  iconClassName,
  logoClassName,
  ...rest
}: PicterLogoProps) {
  return (
    <div
      className={classNames("flex items-center space-x-2.5", className)}
      {...rest}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(iconClassName)}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H28C30.2091 32 32 30.2091 32 28V4C32 1.79086 30.2091 0 28 0H4ZM25 16C25 20.9706 20.9706 25 16 25C15.1753 25 14.3765 24.8891 13.6176 24.6813V20.7291C14.3337 21.0905 15.1431 21.2941 16 21.2941C18.9239 21.2941 21.2941 18.9239 21.2941 16C21.2941 13.0761 18.9239 10.7059 16 10.7059C13.0761 10.7059 10.7059 13.0761 10.7059 16V23.279C8.45976 21.6425 7 18.9917 7 16C7 11.0294 11.0294 7 16 7C20.9706 7 25 11.0294 25 16Z"
          fill="url(#paint0_linear_2645_5755)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2645_5755"
            x1="0"
            y1="32"
            x2="32"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8100D0" />
            <stop offset="1" stopColor="#7862FF" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="50"
        height="15"
        viewBox="0 0 67 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames("flex text-gray-900", logoClassName)}
      >
        <g fill="currentColor">
          <path d="M6.85797 13.2116C10.8154 13.2116 13.4333 11.0807 13.4333 7.12329C13.4333 3.13546 10.8154 1.00457 6.85797 1.00457H0V19.6956H3.86606V13.2116H6.85797ZM9.5977 7.12329C9.5977 8.73668 8.62358 9.89346 6.61444 9.89346H3.86606V4.32268H6.61444C8.62358 4.32268 9.5977 5.44901 9.5977 7.12329Z" />
          <path d="M19.1946 6.08828H15.4198V19.6956H19.1946V6.08828ZM19.5294 2.16134C19.5294 0.913241 18.4944 0 17.3072 0C16.0895 0 15.0545 0.913241 15.0545 2.16134C15.0545 3.43988 16.0895 4.32268 17.3072 4.32268C18.4944 4.32268 19.5294 3.43988 19.5294 2.16134Z" />
          <path d="M21.1942 12.9072C21.1942 17.1081 23.873 20 27.7391 20C31.1485 20 33.3099 18.0213 33.7056 15.0989H29.9917C29.7787 16.1339 28.9567 16.7732 27.7391 16.7732C26.0039 16.7732 24.9385 15.1903 24.9385 12.9072C24.9385 10.5936 26.0039 9.01066 27.7391 9.01066C28.9567 9.01066 29.7787 9.64992 29.9917 10.6849H33.7056C33.3099 7.793 31.1485 5.78387 27.7391 5.78387C23.873 5.78387 21.1942 8.6758 21.1942 12.9072Z" />
          <path d="M35.4958 15.586C35.4958 18.5997 37.231 19.9696 40.0012 19.9696C41.0057 19.9696 41.919 19.8174 42.4365 19.5434V16.1948C42.0103 16.4688 41.371 16.6514 40.7926 16.6514C39.8185 16.6514 39.2401 16.1644 39.2401 15.0989V9.13242H42.2234V6.08828H39.2401V2.40487H35.4958C35.4958 6.79858 35.4958 11.1923 35.4958 15.586Z" />
          <path d="M52.5014 15.7496C52.1066 16.5342 51.302 16.9559 50.1452 16.9559C48.7283 16.9559 47.5639 16.0495 47.254 14.2154C47.254 14.2154 54.8852 14.1879 56.2595 14.1879C56.5744 9.01299 54.5854 5.78387 50.0843 5.78387C46.2792 5.78387 43.5394 8.79757 43.5394 12.9072C43.5394 17.1081 46.1878 20 50.1452 20C53.5547 20 55.4949 18.1484 56.0796 15.7496H52.5014ZM50.1148 8.85845C51.6318 8.85845 52.6113 9.86757 52.6113 11.8216H47.234C47.4639 9.78761 48.6536 8.85845 50.1148 8.85845Z" />
          <path d="M66.4935 6.0274C66.2804 5.90563 65.7021 5.78387 65.0628 5.78387C63.7234 5.78387 62.5753 6.5942 62.0186 7.7936V6.08828H58.2439V19.6956H62.0186V13.1809C62.0186 9.7926 63.4349 9.34551 64.6062 9.34551C65.3063 9.34551 65.976 9.49772 66.4935 9.80213V6.0274Z" />
        </g>
      </svg>
    </div>
  );
}
