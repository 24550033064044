import classNames from "clsx";
import React, {
  PropsWithChildren,
  ReactElement,
  cloneElement,
  Children,
} from "react";

type GridProps = PropsWithChildren<{
  className?: string;
  rowBorder?: boolean;
}>;

type GridItemProps = PropsWithChildren<{
  className?: string;
  /** Don't set this prop. Used by parent for rowBorder feature */
  rowBorder?: boolean;
  /** Don't set this prop. Used by parent for rowBorder feature */
  index?: number;
  /** Don't set this prop. Used by parent for rowBorder feature */
  itemCount?: number;
}>;

export default function Grid({
  children,
  className,
  rowBorder = false,
  ...rest
}: GridProps) {
  // We need to filter out null values in children, since they aren't
  // rendered but they skew the index-based row-border implmenetation.
  // It's not really documented, but Children.toArray() filters out
  // null values automatically.
  // https://github.com/facebook/react/issues/4867#issuecomment-143061047
  const filteredChildren = Children.toArray(children) as ReactElement[];
  const itemCount = filteredChildren.length;
  return (
    <ul
      className={classNames(
        "grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3",
        className,
      )}
      {...rest}
    >
      {filteredChildren.map((child, index) =>
        cloneElement(child, { index, rowBorder, itemCount }),
      )}
    </ul>
  );
}

Grid.Item = function Item({
  children,
  className,
  rowBorder,
  index,
  itemCount,
  ...rest
}: GridItemProps) {
  index = index!;

  const classes = classNames("col-span-1", className);
  // <Grid> defines 1 col for mobile, 2 cols for small screens
  // and 3 cols for large screens. Since CSS grid does not support
  // any concept of row-borders, we need to add them as full-span
  // grid-elements to the dom. Since Tailwind.css is mobile first,
  // the border-element will be visible by default.
  const borderClasses = classNames("col-span-full h-px bg-gray-300", {
    // Always hide border of last item
    hidden: index + 1 === itemCount,
    // Hide every odd border-element on small screens
    "sm:hidden": (index + 1) % 2 !== 0,
    // Display every third border-element on large screens
    "lg:hidden": (index + 1) % 3 !== 0,
    "lg:block": (index + 1) % 3 === 0 && index + 1 !== itemCount,
  });
  return (
    <>
      <li className={classes} {...rest}>
        {children}
      </li>
      {rowBorder ? <li className={borderClasses}></li> : null}
    </>
  );
};
