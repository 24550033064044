import classNames from "clsx";

type PicterContestsLogoProps = {
  className?: string;
  iconClassName?: string;
  logoClassName?: string;
};

export default function PicterContestsLogo({
  className,
  iconClassName,
  logoClassName,
  ...rest
}: PicterContestsLogoProps) {
  return (
    <div
      className={classNames("flex items-center space-x-2.5", className)}
      {...rest}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(iconClassName)}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H28C30.2091 32 32 30.2091 32 28V4C32 1.79086 30.2091 0 28 0H4ZM25 16C25 20.9706 20.9706 25 16 25C15.1753 25 14.3765 24.8891 13.6176 24.6813V20.7291C14.3337 21.0905 15.1431 21.2941 16 21.2941C18.9239 21.2941 21.2941 18.9239 21.2941 16C21.2941 13.0761 18.9239 10.7059 16 10.7059C13.0761 10.7059 10.7059 13.0761 10.7059 16V23.279C8.45976 21.6425 7 18.9917 7 16C7 11.0294 11.0294 7 16 7C20.9706 7 25 11.0294 25 16Z"
          fill="url(#paint0_linear_2645_5755)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2645_5755"
            x1="0"
            y1="32"
            x2="32"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8100D0" />
            <stop offset="1" stopColor="#7862FF" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="136"
        height="16"
        viewBox="0 0 136 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames("flex text-gray-900", logoClassName)}
      >
        <g fill="currentColor">
          <path d="M5.48638 10.5693C8.65228 10.5693 10.7467 8.86454 10.7467 5.69863C10.7467 2.50837 8.65228 0.803653 5.48638 0.803653H0V15.7565H3.09285V10.5693H5.48638ZM7.67816 5.69863C7.67816 6.98935 6.89886 7.91476 5.29155 7.91476H3.09285V3.45814H5.29155C6.89886 3.45814 7.67816 4.35921 7.67816 5.69863Z" />
          <path d="M15.3556 4.87062H12.3359V15.7565H15.3556V4.87062ZM15.6235 1.72907C15.6235 0.730593 14.7955 0 13.8457 0C12.8716 0 12.0436 0.730593 12.0436 1.72907C12.0436 2.7519 12.8716 3.45814 13.8457 3.45814C14.7955 3.45814 15.6235 2.7519 15.6235 1.72907Z" />
          <path d="M16.9553 10.3257C16.9553 13.6865 19.0984 16 22.1913 16C24.9188 16 26.6479 14.417 26.9645 12.0791H23.9934C23.8229 12.9072 23.1654 13.4186 22.1913 13.4186C20.8031 13.4186 19.9508 12.1522 19.9508 10.3257C19.9508 8.47489 20.8031 7.20852 22.1913 7.20852C23.1654 7.20852 23.8229 7.71994 23.9934 8.54795H26.9645C26.6479 6.2344 24.9188 4.62709 22.1913 4.62709C19.0984 4.62709 16.9553 6.94064 16.9553 10.3257Z" />
          <path d="M28.3967 12.4688C28.3967 14.8798 29.7848 15.9756 32.0009 15.9756C32.8046 15.9756 33.5352 15.8539 33.9492 15.6347V12.9559C33.6082 13.175 33.0968 13.3212 32.6341 13.3212C31.8548 13.3212 31.3921 12.9315 31.3921 12.0791V7.30594H33.7787V4.87062H31.3921V1.9239H28.3967C28.3967 5.43886 28.3967 8.95383 28.3967 12.4688Z" />
          <path d="M42.0011 12.5997C41.6853 13.2274 41.0416 13.5647 40.1162 13.5647C38.9826 13.5647 38.0511 12.8396 37.8032 11.3723C37.8032 11.3723 43.9082 11.3503 45.0076 11.3503C45.2595 7.21039 43.6683 4.62709 40.0675 4.62709C37.0233 4.62709 34.8315 7.03805 34.8315 10.3257C34.8315 13.6865 36.9503 16 40.1162 16C42.8437 16 44.3959 14.5187 44.8637 12.5997H42.0011ZM40.0918 7.08676C41.3055 7.08676 42.0891 7.89405 42.0891 9.45727H37.7872C37.9711 7.83008 38.9229 7.08676 40.0918 7.08676Z" />
          <path d="M53.1948 4.82192C53.0244 4.72451 52.5616 4.62709 52.0502 4.62709C50.9787 4.62709 50.0602 5.27536 49.6149 6.23488V4.87062H46.5951V15.7565H49.6149V10.5447C49.6149 7.83408 50.7479 7.47641 51.6849 7.47641C52.2451 7.47641 52.7808 7.59817 53.1948 7.84171V4.82192Z" />
          <path d="M57.6001 8.31488C57.6001 12.96 60.5185 16 64.2881 16C67.4011 16 69.4926 14.176 70.1006 11.3792H68.2036C67.5956 13.1302 66.3553 14.2733 64.2881 14.2733C61.4183 14.2733 59.5214 11.8413 59.5214 8.31488C59.5214 4.76416 61.4427 2.35648 64.2881 2.35648C66.3553 2.35648 67.5956 3.49952 68.2036 5.22624H70.1006C69.4926 2.45376 67.4254 0.629761 64.2881 0.629761C60.5185 0.629761 57.6001 3.66976 57.6001 8.31488Z" />
          <path d="M81.1873 10.3821C81.1873 7.07456 79.1687 4.76416 76.226 4.76416C73.2589 4.76416 71.2647 7.07456 71.2647 10.3821C71.2647 13.6896 73.2589 16 76.226 16C79.1687 16 81.1873 13.6896 81.1873 10.3821ZM79.3389 10.3821C79.3389 12.7411 78.1716 14.3949 76.226 14.3949C74.2804 14.3949 73.113 12.7411 73.113 10.3821C73.113 8.02304 74.2804 6.36928 76.226 6.36928C78.1716 6.36928 79.3389 8.02304 79.3389 10.3821Z" />
          <path d="M83.0487 15.7811H84.8727V8.4608C85.2618 7.29344 86.3562 6.44224 87.6209 6.44224C89.2503 6.44224 90.0042 7.488 90.0042 8.99584V15.7811H91.8525V8.704C91.8525 6.34496 90.5149 4.76416 88.0586 4.76416C86.6724 4.76416 85.505 5.49376 84.8727 6.34496V4.98304H83.0487V15.7811Z" />
          <path d="M94.8502 13.0573C94.8502 15.0515 96.0662 16 97.8172 16C98.4738 16 99.1062 15.8541 99.5196 15.6352V13.9328C99.1548 14.176 98.6198 14.3706 98.1091 14.3706C97.1849 14.3706 96.6742 13.9085 96.6742 12.8627V6.53952H99.2764V4.98304H96.6742V2.1376H94.8502V4.98304H93.0991V6.53952H94.8502V13.0573Z" />
          <path d="M108.141 12.5466C107.849 13.7626 106.852 14.4435 105.514 14.4435C103.69 14.4435 102.402 13.033 102.377 10.7226H110.014V10.0659C110.014 6.92864 108.433 4.76416 105.466 4.76416C102.523 4.76416 100.553 7.17184 100.553 10.4064C100.553 13.7382 102.572 16 105.514 16C107.946 16 109.479 14.5894 109.868 12.5466H108.141ZM105.49 6.32064C107.29 6.32064 108.141 7.7312 108.165 9.33632H102.426C102.693 7.43936 103.861 6.32064 105.49 6.32064Z" />
          <path d="M110.986 12.8141C111.327 14.8083 113.053 16 115.412 16C117.723 16 119.425 14.784 119.425 12.7411C119.425 11.2333 118.525 10.2118 116.677 9.72544L114.585 9.21472C113.564 8.92288 113.078 8.5824 113.078 7.8528C113.078 6.80704 114.075 6.29632 115.145 6.29632C116.458 6.29632 117.212 6.85568 117.528 7.82848H119.352C118.963 5.93152 117.553 4.76416 115.169 4.76416C112.98 4.76416 111.302 6.0288 111.302 7.95008C111.302 9.62816 112.372 10.4064 113.905 10.7955L116.045 11.3549C117.163 11.6467 117.65 12.1088 117.65 12.8627C117.65 13.9571 116.726 14.4678 115.437 14.4678C114.172 14.4678 113.224 13.9328 112.883 12.8141H110.986Z" />
          <path d="M121.859 13.0573C121.859 15.0515 123.075 16 124.826 16C125.482 16 126.115 15.8541 126.528 15.6352V13.9328C126.163 14.176 125.628 14.3706 125.118 14.3706C124.193 14.3706 123.683 13.9085 123.683 12.8627V6.53952H126.285V4.98304H123.683V2.1376H121.859V4.98304H120.108V6.53952H121.859V13.0573Z" />
          <path d="M127.488 12.8141C127.828 14.8083 129.555 16 131.914 16C134.224 16 135.927 14.784 135.927 12.7411C135.927 11.2333 135.027 10.2118 133.178 9.72544L131.087 9.21472C130.066 8.92288 129.579 8.5824 129.579 7.8528C129.579 6.80704 130.576 6.29632 131.646 6.29632C132.96 6.29632 133.714 6.85568 134.03 7.82848H135.854C135.465 5.93152 134.054 4.76416 131.671 4.76416C129.482 4.76416 127.804 6.0288 127.804 7.95008C127.804 9.62816 128.874 10.4064 130.406 10.7955L132.546 11.3549C133.665 11.6467 134.151 12.1088 134.151 12.8627C134.151 13.9571 133.227 14.4678 131.938 14.4678C130.674 14.4678 129.725 13.9328 129.385 12.8141H127.488Z" />
        </g>
      </svg>
    </div>
  );
}
