import { useMemo } from "react";

export default function useSubmissionStatus(
  submissionStartDate: number | string,
  submissionEndDate: number | string,
  submissionStatus?:
    | "IN_EDIT"
    | "SUBMITTED"
    | "PAYMENT_IN_PROGRESS"
    | "DRAFT"
    | "LOADING",
) {
  const status = useMemo(() => {
    const start = new Date(submissionStartDate).valueOf();
    const end = new Date(submissionEndDate).valueOf();
    const now = Date.now();
    if (
      now >= end &&
      (submissionStatus === undefined || submissionStatus !== "SUBMITTED")
    ) {
      return "CLOSED";
    }
    if (submissionStatus) return submissionStatus;
    if (now < start) {
      return "UPCOMING";
    }

    return "OPEN";
  }, [submissionEndDate, submissionStartDate, submissionStatus]);
  return status;
}
