import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import { FooterLink } from "@shared/elements";

import { MESSAGE_PREFIX } from "../constants";

export default function LegalLinks() {
  const t = useTranslations(`${MESSAGE_PREFIX}LegalLinks`);
  const router = useRouter();

  return (
    <>
      <FooterLink
        color="muted"
        className="!text-sm"
        href="https://www.picter.com/imprint"
      >
        {t("imprint")}
      </FooterLink>{" "}
      ·{" "}
      <FooterLink
        color="muted"
        className="!text-sm"
        href="https://www.picter.com/terms-of-use"
      >
        {t("terms")}
      </FooterLink>{" "}
      ·{" "}
      <FooterLink
        color="muted"
        className="!text-sm"
        href="https://www.picter.com/privacy-policy"
      >
        {t("privacy")}
      </FooterLink>{" "}
      ·{" "}
      <FooterLink
        color="muted"
        className="!text-sm"
        href={{
          pathname: router.pathname,
          query: { ...router.query, cookieBanner: true },
        }}
        scroll={false}
        replace
        shallow
      >
        {t("cookies")}
      </FooterLink>
    </>
  );
}

LegalLinks.messages = [`${MESSAGE_PREFIX}LegalLinks`];
