import { useTranslations } from "next-intl";

import { TCall, TSubmissionCategory } from "@shared/types";
import {
  sortedGroupByPrice,
  isGraduationPerProject,
  isNoGraduationPerProject,
  isGraduationPerImage,
  isNoGraduationPerImage,
} from "@shared/utils";

export default function useSubmissionCategoryPriceDescription(
  category: Pick<
    TSubmissionCategory,
    "price" | "priceGraduation" | "imagePrice" | "imagePriceGraduation"
  >,
  currency: TCall["currency"],
) {
  const t = useTranslations(
    "packages.hooks.useSubmissionCategoryPriceDescription",
  );

  if (isNoGraduationPerProject(category)) {
    return [t("noGraduationPerProject", { currency, price: category.price })];
  }

  if (isGraduationPerProject(category)) {
    const groupedPriceGraduation = sortedGroupByPrice(
      category.priceGraduation as number[],
    );

    return groupedPriceGraduation.map(([value, count], index) => {
      const price = (category.price ?? 0) * value;

      if (index === 0) {
        return t("graduationPerProjectFirst", { price, currency, count });
      }

      if (index === groupedPriceGraduation.length - 1) {
        return t("graduationPerProjectLast", { price, currency });
      }

      return t("graduationPerProjectMiddle", { price, currency, count });
    });
  }

  if (isNoGraduationPerImage(category)) {
    return [
      t("noGraduationPerImage", { currency, price: category.imagePrice }),
    ];
  }

  if (isGraduationPerImage(category)) {
    const groupedPriceGraduation = sortedGroupByPrice(
      category.imagePriceGraduation as number[],
    );

    return groupedPriceGraduation.map(([value, count], index) => {
      const price = (category.imagePrice ?? 0) * value;

      if (index === 0) {
        return t("graduationPerImageFirst", { currency, price, count });
      }

      if (index === groupedPriceGraduation.length - 1) {
        return t("graduationPerImageLast", { currency, price });
      }

      return t("graduationPerImageMiddle", { currency, price, count });
    });
  }

  return null;
}

useSubmissionCategoryPriceDescription.messages = [
  "packages.hooks.useSubmissionCategoryPriceDescription",
];
