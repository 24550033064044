import classNames from "clsx";
import { useTranslations } from "next-intl";
import { useMemo } from "react";
import {
  HiOutlineTrophy,
  HiArrowsPointingIn,
  HiOutlineMegaphone,
  HiOutlineLightBulb,
  HiOutlineCheckCircle,
  HiOutlineGlobeAmericas,
} from "react-icons/hi2";

import { urls } from "@shared/constants";
import { Text } from "@shared/elements";

import { MESSAGE_PREFIX } from "../constants";
import { DarkSquareLogo } from "../PicterLogo";

type ProductSwitcherProps = {
  className?: string;
};

export default function ProductSwitcher({
  className,
  ...rest
}: ProductSwitcherProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}ProductSwitcher`);

  const products = useMemo(
    () => [
      {
        name: t("openContests"),
        description: t("openContestsDesc"),
        Icon: HiOutlineTrophy,
        href: urls.index,
      },
      {
        name: "Connects",
        description: t("connectsDesc"),
        Icon: HiArrowsPointingIn,
        href: "https://www.picter.com/connects",
      },
      {
        name: "Journey",
        description: t("journeyDesc"),
        Icon: HiOutlineLightBulb,
        href: "https://www.picter.com/journey/",
      },
      {
        name: t("contestHosts"),
        description: t("contestHostsDesc"),
        Icon: HiOutlineGlobeAmericas,
        href: "https://www.picter.com/contest-hosting",
      },
      {
        name: "Workspace",
        description: t("workspaceDesc"),
        Icon: HiOutlineCheckCircle,
        href: "https://www.picter.com/workspace",
      },
      {
        name: t("advertise"),
        description: t("advertiseDesc"),
        Icon: HiOutlineMegaphone,
        href: "https://www.picter.com/media",
      },
      {
        name: t("allPicterSolutions"),
        description: t("allPicterSolutionsDesc"),
        Icon: () => (
          <div className="w-10 h-10 flex justify-center items-center ">
            <DarkSquareLogo className="w-[34px] h-[34px]" />
          </div>
        ),
        href: "https://www.picter.com/",
      },
    ],
    [t],
  );

  return (
    <ul className={classNames("relative grid gap-2", className)} {...rest}>
      {products.map((item) => (
        <li
          key={item.name as string}
          className="p-2 hover:bg-gray-50 rounded-lg"
        >
          <a href={item.href}>
            <div className="group space-x-2 flex items-center justify-start gap-2">
              <div className="w-10 h-10 flex justify-center items-center">
                <item.Icon className="text-gray-900 w-10 h-10" />
              </div>
              <div className="flex flex-col justify-start items-start gap-1">
                <Text weight="medium">{item.name}</Text>
                <Text size="sm" color="muted">
                  {item.description}
                </Text>
              </div>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
}

ProductSwitcher.messages = [`${MESSAGE_PREFIX}ProductSwitcher`];
