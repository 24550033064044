import classNames from "clsx";
import Link from "next/link";
import { MouseEventHandler, PropsWithChildren } from "react";

type MenuItemProps = PropsWithChildren<{
  className?: string;
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}>;

export default function MenuItem({
  className,
  children,
  href,
  onClick,
  ...rest
}: MenuItemProps) {
  const classes = classNames(
    "flex items-center px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100",
    className,
  );
  if (!href) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <a onClick={onClick} className={classes} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link className={classes} {...rest} href={href}>
      {children}
    </Link>
  );
}
