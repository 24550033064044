export function toQueryString(
  params: Record<string, string | number | undefined>,
) {
  return Object.entries(params)
    .map(([key, value]) => {
      if (value !== undefined) {
        return `${key}=${encodeURIComponent(value)}`;
      }
      return null;
    })
    .filter(Boolean)
    .join("&");
}

export function filterNullAndUndefinedValuesFromObject(
  params: Record<string, string | number | undefined | null>,
) {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([, value]) => !(value === null || value === undefined),
    ),
  ) as Record<string, string | number>;
}
