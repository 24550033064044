import { Transition } from "@headlessui/react";
import classNames from "clsx";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useCallback, useEffect, useState, Fragment } from "react";
import { HiInformationCircle } from "react-icons/hi";

import { Anchor, Button, Container, Text } from "@shared/elements";
import {
  acceptAllCookies,
  acceptFunctionalCookies,
  COOKIE_CONSENT_COOKIE_NAME,
  COOKIE_VALUES,
} from "@shared/tracking";

import { MESSAGE_PREFIX } from "../constants";

type CookieBannerProps = {
  className?: string;
};

// Set correct domain on production, beta, review and staging environments.
// otherwise subdomains like app.picter.com will be used by default (which
// makes the cookie invisible on other subdomains of picter.com).
function getDomain(host: string) {
  const match = /(picter\.(?:com|io))/.exec(host);
  if (match) {
    return match[1];
  }
  return undefined;
}

export default function CookieBanner({
  className,
  ...rest
}: CookieBannerProps) {
  const [show, setShow] = useState(false);
  const router = useRouter();
  const t = useTranslations(`${MESSAGE_PREFIX}CookieBanner`);
  useEffect(() => {
    const cookieConsent = Cookies.get(COOKIE_CONSENT_COOKIE_NAME);
    if (cookieConsent === undefined) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (router.query.cookieBanner) {
      setShow(true);
    }
  }, [router.query.cookieBanner]);

  const acceptAll = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, COOKIE_VALUES.ALL, {
      expires: 730,
      domain: getDomain(window.location.hostname),
    });
    acceptAllCookies();
    // remove cookieBanner query parameter
    if (router.query.cookieBanner) {
      const { pathname, query } = router;
      delete query.cookieBanner;
      router.replace({ pathname, query }, undefined, {
        shallow: true,
      });
    }
    setShow(false);
  }, [router]);

  const acceptFunctional = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, COOKIE_VALUES.FUNCTIONAL, {
      expires: 14,
      domain: getDomain(window.location.hostname),
    });
    acceptFunctionalCookies();
    // remove cookieBanner query parameter
    if (router.query.cookieBanner) {
      const { pathname, query } = router;
      delete query.cookieBanner;
      router.replace({ pathname, query }, undefined, {
        shallow: true,
      });
    }

    setShow(false);
  }, [router]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform transition-transform duration-150"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transform transition-transform duration-150"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
    >
      <div
        className={classNames(
          "fixed inset-x-0 bottom-0 bg-primary-600 text-white",
          className,
        )}
        {...rest}
      >
        <Container className="py-3">
          <div className="sm:flex items-center justify-between flex-wrap">
            <div className="flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-primary-800">
                <HiInformationCircle
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <Text color="current" className="ml-3">
                {t.rich("message", {
                  // eslint-disable-next-line react/display-name
                  link: (children) => (
                    <Anchor href="https://www.picter.com/privacy-policy">
                      {children}
                    </Anchor>
                  ),
                })}
              </Text>
            </div>
            <div className="mt-2 sm:mt-0 space-y-3 sm:space-y-0 sm:space-x-6 flex-shrink-0 w-full sm:w-auto">
              <Button
                variant="text"
                className="w-full sm:w-auto !text-white hover:text-white"
                onClick={acceptFunctional}
              >
                {t("acceptFunctional")}
              </Button>
              <Button
                variant="white"
                className="w-full sm:w-auto"
                onClick={acceptAll}
              >
                {t("acceptAll")}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </Transition>
  );
}

CookieBanner.messages = [`${MESSAGE_PREFIX}CookieBanner`];
