import classNames from "clsx";
import { PropsWithChildren } from "react";

type AnchorProps = PropsWithChildren<{
  href: string;
  target?: string;
  className?: string;
}>;

export default function Anchor({ className, children, ...rest }: AnchorProps) {
  return (
    <a className={classNames("underline", className)} {...rest}>
      {children}
    </a>
  );
}
