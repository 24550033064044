import classNames from "clsx";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { PropsWithChildren } from "react";

import { urls } from "@shared/constants";
import { Container, PicterLogo, Text, Tooltip } from "@shared/elements";



import { MESSAGE_PREFIX } from "../constants";

type PublicFooterProps = PropsWithChildren<{
  className?: string;
}>;

export default function HostedByFooter({
  className,
  children,
}: PublicFooterProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}HostedByFooter`);

  return (
    <Container className={classNames("pt-12 pb-40 lg:pb-12", className)}>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <Text
            as="p"
            size="sm"
            color="custom"
            className="px-2 bg-white text-gray-500"
          >
            {t("submissionsHostedBy")}
          </Text>
        </div>
      </div>
      <div className="mt-3 pl-4 pr-4 sm:pl-6 sm:pr-6 py-5 flex items-center justify-center">
        <Tooltip
          content={t("logoTooltip")}
          containerClassName="flex items-center"
          skidding={8}
          distance={8}
          placement="top"
        >
          <div>
            <Link href={urls.index}>
              <PicterLogo className="h-7 sm:h-8 w-auto" />
            </Link>
          </div>
        </Tooltip>
      </div>
      {children}
    </Container>
  );
}

HostedByFooter.messages = [`${MESSAGE_PREFIX}HostedByFooter`];
