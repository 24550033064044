export function DarkSquareLogo({ className = "" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.83333 0C1.26853 0 0 1.26853 0 2.83333V31.1667C0 32.7315 1.26853 34 2.83333 34H31.1667C32.7315 34 34 32.7315 34 31.1667V2.83333C34 1.26853 32.7315 0 31.1667 0H2.83333ZM26.5625 17C26.5625 22.2812 22.2812 26.5625 17 26.5625C16.1237 26.5625 15.275 26.4446 14.4688 26.2239V22.0247C15.2296 22.4087 16.0895 22.625 17 22.625C20.1066 22.625 22.625 20.1066 22.625 17C22.625 13.8934 20.1066 11.375 17 11.375C13.8934 11.375 11.375 13.8934 11.375 17V24.7339C8.9885 22.9952 7.4375 20.1787 7.4375 17C7.4375 11.7188 11.7188 7.4375 17 7.4375C22.2812 7.4375 26.5625 11.7188 26.5625 17Z"
          fill="#111827"
        />
      </g>
    </svg>
  );
}
