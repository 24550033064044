import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export default function useUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList,
) {
  const firstEffectRef = useRef(true);
  useEffect(() => {
    if (firstEffectRef.current === false) {
      return effect();
    }
    firstEffectRef.current = false;
  }, deps);
}
