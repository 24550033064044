import {
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
  forwardRef,
} from "react";
import { HiExclamationCircle, HiXCircle } from "react-icons/hi";

import { classNames } from "@shared/utils";

import Label from "./Label";
import Text from "./Text";

type BaseInputProps = {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  error?: ReactNode;
  hiddenLabel?: boolean;
  hint?: ReactNode;
  id?: string;
  label: string;
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onClear?: () => void;
  placeholder?: string;
  required?: boolean;
  size?: "base" | "lg";
  prefix?: string;
};

// If input type is 'date', value is required
type DateInputProps = BaseInputProps & {
  type: "date";
  value: string | null;
};

// If input type is not 'date', value is optional
type OtherInputProps = BaseInputProps & {
  type?: "text" | "password" | "email" | "number" | "tel" | "url";
  value?: string | number | null; // Optional for other types
};

export type InputProps = DateInputProps | OtherInputProps;

const Input = forwardRef<HTMLInputElement | null, InputProps>(
  (
    {
      className,
      disabled,
      error,
      hiddenLabel = false,
      hint,
      id,
      label,
      name,
      placeholder,
      required = false,
      size = "base",
      type = "text",
      onClear,
      prefix,
      value,
      ...rest
    }: InputProps,
    ref,
  ) => {
    return (
      <div className={classNames("space-y-2", className)}>
        <Label
          htmlFor={id || name}
          className={classNames(hiddenLabel && "sr-only")}
          required={required}
        >
          {label}
        </Label>
        <div className="mt-1 rounded-md flex relative">
          {prefix && (
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 bg-gray-50 sm:text-sm">
              {prefix}
            </span>
          )}
          <input
            type={type}
            name={name}
            id={id || name}
            className={classNames(
              {
                "border-gray-300 text-gray-900 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500":
                  error === undefined,
                "opacity-50 cursor-not-allowed": disabled,
                "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 pr-10":
                  error,
                "text-sm": size === "base",
                "text-base py-3": size === "lg",
                "rounded-none rounded-r-md": prefix,
                "rounded-md": !prefix,
                // if type is date, the placeholder needs to be styled with text-* classes
                "text-gray-400": type === "date" && !value,
                "text-red-300": type === "date" && !value && error,
              },
              "shadow-sm block w-full",
            )}
            placeholder={placeholder || (hiddenLabel ? label : undefined)}
            aria-describedby={hint ? `${id || name}-description` : undefined}
            disabled={disabled ? true : undefined}
            required={required}
            value={value === null ? "" : value}
            {...rest}
            ref={ref}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {error ? (
              <HiExclamationCircle
                className="h-5 w-5 text-red-500 pointer-events-none"
                aria-hidden="true"
              />
            ) : null}
            {value && onClear ? (
              <HiXCircle
                className="h-5 w-5 text-gray-400 z-10 cursor-pointer"
                aria-hidden="true"
                onClick={onClear}
              />
            ) : null}
          </div>
        </div>
        {!error && hint ? (
          <Text
            as="p"
            size="sm"
            color="muted"
            className="mt-2"
            id={`${id || name}-description`}
          >
            {hint}
          </Text>
        ) : null}
        {error ? (
          <Text
            as="p"
            size="sm"
            color="danger"
            className="mt-2"
            id={`${id || name}-description`}
          >
            {error}
          </Text>
        ) : null}
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;
