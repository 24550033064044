import classNames from "clsx";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import { HiOutlineX } from "react-icons/hi";

export type AlertProps = PropsWithChildren<{
  className?: string;
  contentClassName?: string;
  color: "green" | "yellow" | "red" | "primary" | "secondary" | "custom";
  onClose?: MouseEventHandler<HTMLButtonElement>;
  Icon?: React.ComponentType<{ className?: string }>;
}>;

export default function Alert({
  children,
  className,
  contentClassName,
  color = "secondary",
  onClose,
  Icon,
  ...rest
}: AlertProps) {
  const classes = classNames(
    {
      "dark:bg-green-800 dark:text-green-100 bg-green-100 text-green-800":
        color === "green",
      "dark:bg-yellow-800 dark:text-yellow-100 bg-yellow-100 text-yellow-800":
        color === "yellow",
      "dark:bg-red-800 dark:text-red-100 bg-red-100 text-red-800":
        color === "red",
      "dark:bg-primary-800 dark:text-primary-100 bg-primary-50 text-primary-800":
        color === "primary",
      "dark:bg-secondary-800 dark:text-secondary-100 bg-secondary-100 text-secondary-800":
        color === "secondary",
    },
    "rounded-md p-4",
    className,
  );

  const iconClasses = classNames("h-5 w-5", {
    "text-green-400": color === "green",
    "text-yellow-400": color === "yellow",
    "text-red-400": color === "red",
    "text-primary-500": color === "primary",
    "text-secondary-400": color === "secondary",
  });

  const contentClasses = classNames("text-sm font-medium", contentClassName, {
    "text-green-800": color === "green",
    "text-yellow-800": color === "yellow",
    "text-red-800": color === "red",
    "text-primary-800": color === "primary",
    "text-secondary-800": color === "secondary",
  });

  const buttonClasses = classNames(
    "inline-flex rounded-md p-1.5 focus:outline-none",
    {
      "text-green-400": color === "green",
      "text-yellow-400": color === "yellow",
      "text-red-400": color === "red",
      "text-primary-400": color === "primary",
      "text-secondary-400": color === "secondary",
    },
  );

  return (
    <div className={classes} {...rest}>
      <div className="flex">
        {Icon && (
          <div className="flex-shrink-0 mr-3">
            <Icon className={iconClasses} aria-hidden="true" />
          </div>
        )}
        <div className={contentClasses}>{children}</div>
        {onClose && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button type="button" className={buttonClasses} onClick={onClose}>
                <span className="sr-only">Dismiss</span>
                <HiOutlineX className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
