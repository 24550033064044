import { Switch } from "@headlessui/react";
import classNames from "clsx";
import { ReactNode, useCallback } from "react";

type ToggleProps = {
  onChange: (on: boolean) => void;
  iconOff?: ReactNode;
  iconOn?: ReactNode;
  value?: boolean;
};

export default function Toggle({
  onChange,
  iconOff,
  iconOn,
  value = false,
}: ToggleProps) {
  const changeHandler = useCallback(
    (newValue: boolean) => {
      onChange(newValue);
    },
    [onChange],
  );
  return (
    <Switch
      checked={value}
      onChange={changeHandler}
      className={classNames(
        value ? "bg-indigo-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          value ? "translate-x-5" : "translate-x-0",
          value ? "bg-gray-900" : "bg-white",
          "pointer-events-none relative inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200",
        )}
      >
        <span
          className={classNames(
            value
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
          )}
          aria-hidden="true"
        >
          {iconOff}
        </span>
        <span
          className={classNames(
            value
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
          )}
          aria-hidden="true"
        >
          {iconOn}
        </span>
      </span>
    </Switch>
  );
}
