import {
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
  forwardRef,
} from "react";
import { HiExclamationCircle } from "react-icons/hi";

import { classNames } from "@shared/utils";

import Label from "./Label";
import Text from "./Text";

export type TextareaProps = {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  error?: ReactNode;
  hiddenLabel?: boolean;
  hint?: ReactNode;
  id?: string;
  label: string;
  name: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  characterCount?: number;
  characterLimit?: number;
  required?: boolean;
  size?: "base" | "lg";
  value?: string | number;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      disabled,
      error,
      hiddenLabel = false,
      hint,
      id,
      label,
      name,
      placeholder,
      characterCount,
      characterLimit,
      required = false,
      size = "base",
      ...rest
    }: TextareaProps,
    ref,
  ) => {
    return (
      <div className={classNames("space-y-2", className)}>
        <Label
          htmlFor={id || name}
          className={classNames(hiddenLabel && "sr-only")}
          required={required}
        >
          {label}
        </Label>
        <div className="mt-1 relative flex flex-col">
          <textarea
            name={name}
            id={id || name}
            className={classNames(
              {
                "border-gray-300 text-gray-900 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500":
                  error === undefined,
                "opacity-50 cursor-not-allowed": disabled,
                "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 pr-10":
                  error,
                "text-sm": size === "base",
                "text-base py-3": size === "lg",
              },
              "shadow-sm block w-full rounded-md min-h-[96px]",
            )}
            placeholder={placeholder || (hiddenLabel ? label : undefined)}
            aria-describedby={hint ? `${id || name}-description` : undefined}
            disabled={disabled ? true : undefined}
            required={required}
            {...rest}
            ref={ref}
          />
          {characterLimit && (
            <Text
              as="span"
              size="sm"
              color="muted"
              className="mt-2 ml-auto"
              aria-describedby={`${id || name}-character-limits`}
            >
              {`${characterCount || 0} / ${characterLimit}`}
            </Text>
          )}
          {error ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <HiExclamationCircle
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {!error && hint ? (
          <Text
            as="p"
            size="sm"
            color="muted"
            className="mt-2"
            id={`${id || name}-description`}
          >
            {hint}
          </Text>
        ) : null}
        {error ? (
          <Text
            as="p"
            size="sm"
            color="danger"
            className="mt-2"
            id={`${id || name}-description`}
          >
            {error}
          </Text>
        ) : null}
      </div>
    );
  },
);

Textarea.displayName = "Input";

export default Textarea;
