import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef } from "react";
import {
  IntercomProviderProps,
  IntercomProvider,
  useIntercom,
} from "react-use-intercom";

import { useUser } from "@shared/auth";


function IntercomUpdateTracker({ children }: { children: ReactNode }) {
  const user = useUser();
  const router = useRouter();
  const intercom = useIntercom();
  const intercomInitializedRef = useRef(false);

  useEffect(() => {
    if (user.isAuthenticated && user.data) {
      intercom.update({
        userId: user.data.id,
        name: user.data.publicName,
        email: user.data.email,
        createdAt: user.data.createdAt,
      });
      intercomInitializedRef.current = true;
    }
    if (!user.isAuthenticated && intercomInitializedRef.current === true) {
      intercom.shutdown();
    }
  }, [user.data, user.isAuthenticated, intercom]);

  useEffect(() => {
    const handleRouteChange = () => {
      // ping intercom to update the url
      intercom.update();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
}

export default function MyIntercomProvider({
  children,
  ...rest
}: IntercomProviderProps) {
  return (
    <IntercomProvider {...rest}>
      <IntercomUpdateTracker>{children}</IntercomUpdateTracker>
    </IntercomProvider>
  );
}
