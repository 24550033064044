import { useState, useEffect } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 364;

export type TimeUnit = {
  unit: string | ((value: number) => string);
  valueMs: number;
  threshold?: number;
};
const defaultTimeUnits: TimeUnit[] = [
  { unit: "now", valueMs: 20 * SECOND },
  { unit: "%s", valueMs: SECOND, threshold: 45 },
  { unit: "%m", valueMs: MINUTE, threshold: 50 },
  { unit: "%h", valueMs: HOUR, threshold: 22 },
  { unit: "%d", valueMs: DAY, threshold: 8 },
  { unit: "%w", valueMs: 7 * DAY, threshold: 4 },
  { unit: "%mo", valueMs: MONTH, threshold: 12 },
  { unit: "%y", valueMs: YEAR },
];

function getTinyRelativeTime(
  to: Date,
  from = new Date(),
  timeUnits: TimeUnit[],
) {
  const diff = from.valueOf() - to.valueOf();

  for (let i = 0; i < timeUnits.length; i++) {
    const timeUnit = timeUnits[i];
    const timeInUnit = Math.abs(diff) / timeUnit.valueMs;
    if (
      timeInUnit < Math.max(1, timeUnit.threshold || 0) ||
      i === timeUnits.length - 1
    ) {
      if (typeof timeUnit.unit === "string") {
        return timeUnit.unit.replace("%", String(Math.round(timeInUnit)));
      }
      return timeUnit.unit(Math.round(timeInUnit));
    }
  }
  return "";
}

function getRefreshInterval(diff: number) {
  if (diff < MINUTE) {
    return 10 * SECOND;
  }
  if (diff < HOUR) {
    return MINUTE;
  }
  return null;
}

type Props = {
  from?: Date;
  to: Date;
  timeUnits?: TimeUnit[];
};
export default function TinyTimeRelative({
  from = new Date(),
  to,
  timeUnits = defaultTimeUnits,
}: Props) {
  const [timeStamp, setTimeStamp] = useState(0);
  useEffect(() => {
    const timeDiff = Math.abs(Date.now() - to.valueOf());
    const refreshInterval = getRefreshInterval(timeDiff);

    if (refreshInterval !== null) {
      const timer = setTimeout(() => {
        // console.log("tick");
        setTimeStamp(Date.now());
      }, refreshInterval);
      return () => clearTimeout(timer);
    }
  }, [to, timeStamp]);

  return <>{getTinyRelativeTime(to, from, timeUnits)}</>;
}
