import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import Cookies from "js-cookie";
import { ReactNode, useEffect } from "react";
import useSWR from "swr";

import { useUser } from "@shared/auth";

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // @TODO: Send tracking data to our backend
    console.log("Viewed Experiment", experiment, result);
  },
});

async function fetcher() {
  const res = await fetch(
    process.env.NEXT_PUBLIC_GROWTHBOOK_FEATURES_ENDPOINT as string,
  );
  if (res.status !== 200) {
    return null;
  }
  const result = await res.json();
  return result?.features;
}

export default function MyGrowthBookProvider({
  callId,
  children,
}: {
  callId?: string;
  children: ReactNode;
}) {
  if (!process.env.NEXT_PUBLIC_GROWTHBOOK_FEATURES_ENDPOINT) {
    throw new Error(
      "NEXT_PUBLIC_GROWTHBOOK_FEATURES_ENDPOINT env variable is not set. Please set it to the endpoint of the GrowthBook features API.",
    );
  }
  const { data: featureFlags } = useSWR("feature-flags", fetcher);
  const user = useUser();

  useEffect(() => {
    growthbook.setFeatures(featureFlags);
  }, [featureFlags]);

  useEffect(() => {
    growthbook.setAttributes({
      id: user.data?.id,
      lastUserId: Cookies.get("picter-last-user-id"),
      employee:
        user.data?.emailVerified && user.data?.email.endsWith("@picter.com"),
      callId,
    });
  }, [callId, user.data]);
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
}
