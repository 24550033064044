import { useTranslations } from "next-intl";
import { useMemo } from "react";

import { urls } from "@shared/constants";
import { appendUtmParams, getUtmParams } from "@shared/tracking";

import type useSubmissionStatus from "./useSubmissionStatus";

type UseSubmissionCTAButtonInput = {
  status: ReturnType<typeof useSubmissionStatus>;
  callId: string;
  callSlug: string;
  useNewSubmissionWizard: boolean;
};
export default function useSubmissionCTAButton({
  status,
  callId,
  /**
   * Be careful not to use the call-page slug here, as it might
   * be different from the call slug (e.g. for multi-lingual calls)
   */
  callSlug,
  /**
   * Whether to use the simplified submission wizard or not. This
   * might be a temporary flag until the new submission wizard is
   * replacing the old one (which might take some time).
   */
  useNewSubmissionWizard,
}: UseSubmissionCTAButtonInput) {
  const t = useTranslations("packages.hooks.useSubmissionCTAButton");

  const mainButton = useMemo(() => {
    if (status === "OPEN")
      return {
        href: appendUtmParams(
          useNewSubmissionWizard
            ? urls.startSimplifiedSubmission(callSlug)
            : urls.startSubmission(callId, true),
          getUtmParams(),
        ),
        label: t("start"),
      };
    if (status === "DRAFT")
      return {
        href: appendUtmParams(
          useNewSubmissionWizard
            ? urls.startSimplifiedSubmission(callSlug)
            : urls.startSubmission(callId),
          getUtmParams(),
        ),
        label: t("continue"),
      };
    if (status === "IN_EDIT")
      return {
        href: appendUtmParams(urls.finishSubmission(callId), getUtmParams()),
        label: t("continue"),
      };
    if (status === "SUBMITTED")
      return {
        href: appendUtmParams(urls.viewSubmission(callId), getUtmParams()),
        label: t("view"),
      };
    return undefined;
  }, [status, callId, t]);
  return mainButton;
}

useSubmissionCTAButton.messages = ["packages.hooks.useSubmissionCTAButton"];
