import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, {
  useState,
  useEffect,
  Children,
  cloneElement,
  ReactElement,
} from "react";

export type ActiveLinkProps = {
  className?: string;
  activeClassName: string;
  children: React.ReactElement;
} & LinkProps;

export default function ActiveLink({
  children,
  activeClassName,
  ...rest
}: ActiveLinkProps) {
  const { asPath, isReady } = useRouter();

  const child = Children.only(children);
  const childClassName = (child as ReactElement).props.className || "";
  const [className, setClassName] = useState(childClassName);

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(
        (rest.as || rest.href) as string,
        location.href,
      ).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname;
      const newClassName =
        linkPathname === activePathname
          ? `${childClassName} ${activeClassName}`.trim()
          : childClassName;

      if (newClassName !== className) {
        setClassName(newClassName);
      }
    }
  }, [
    asPath,
    isReady,
    rest.as,
    rest.href,
    childClassName,
    activeClassName,
    setClassName,
    className,
  ]);

  return (
    <Link {...rest}>
      {cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
}
