import React, { DetailedHTMLProps, OlHTMLAttributes } from "react";

export function OL(
  props: DetailedHTMLProps<
    OlHTMLAttributes<HTMLOListElement>,
    HTMLOListElement
  >,
) {
  return <ol className="list-decimal pl-4 whitespace-normal" {...props} />;
}
