import React from "react";

import { Text } from "@shared/elements";
import { TImageBlock } from "@shared/types";

import ImageBlock from "../ImageBlock";

type Props = {
  title: string;
  description: string;
  files: Array<TImageBlock["content"]>;
};

export default function GalleryBlock({ title, description, files }: Props) {
  return (
    <div className="flex flex-col items-center lg:items-start gap-y-8 max-w-7xl">
      <div className="flex flex-col items-start w-full px-4 md:px-0 md:w-[700px] md:mx-auto gap-y-4">
        <Text size="3xl" weight="bold">
          {title}
        </Text>
        <Text>{description}</Text>
      </div>
      <div className="relative flex flex-row justify-center flex-wrap overflow-hidden gap-x-8 gap-y-8 px-4 lg:px-0">
        {files.map((file: TImageBlock["content"], index: number) => (
          <ImageBlock
            caption={file.caption}
            description={file.description}
            src={file.src}
            imageHeight={400}
            containerClasses="justify-between"
            key={`${file.src}${index}`}
          />
        ))}
      </div>
    </div>
  );
}
