import classNames from "clsx";
import { useTranslations } from "next-intl";

import { Badge } from "@shared/elements";
import { useAdImpressionTracking } from "@shared/tracking";
import { getCurrentBreakpointIndex } from "@shared/utils";

import { MESSAGE_PREFIX } from "./constants";

export enum Ratio {
  Square = 1,
  FourByOne = 4 / 1,
  FourByThree = 4 / 3,
}

type GenericAdProps = {
  className?: string;
  campaign: string;
  type: "video" | "image";
  src: string[];
  poster?: string[];
  ratio: Ratio | Ratio[];
  clickUrl: string;
  impressionUrl: string;
};

const ratioClasses = {
  [Ratio.Square]: "aspect-w-1 aspect-h-1",
  [Ratio.FourByThree]: "aspect-w-4 aspect-h-3",
  [Ratio.FourByOne]: "aspect-w-4 aspect-h-1",
};

const ratioClassesSm = {
  [Ratio.Square]: "sm:aspect-w-1 sm:aspect-h-1",
  [Ratio.FourByThree]: "sm:aspect-w-4 sm:aspect-h-3",
  [Ratio.FourByOne]: "sm:aspect-w-4 sm:aspect-h-1",
};

function getRatioClasses(ratio: Ratio, index: number) {
  if (index === 0) {
    return ratioClasses[ratio];
  }
  return ratioClassesSm[ratio];
}

export default function GenericAd({
  className,
  src,
  poster,
  type,
  campaign,
  ratio,
  impressionUrl,
  clickUrl,
  ...rest
}: GenericAdProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}GenericAd`);
  const breakpointIndex = getCurrentBreakpointIndex();
  const ref = useAdImpressionTracking({
    impressionUrl,
  });

  return (
    <div className={classNames(className)} {...rest}>
      <Badge color="primary" size="xs">
        {t("badge")}
      </Badge>
      <a
        href={clickUrl}
        className={classNames(
          "block w-full overflow-hidden mt-3",
          Array.isArray(ratio)
            ? ratio.map((r, index) => getRatioClasses(r, index))
            : ratioClasses[ratio],
        )}
        ref={ref}
      >
        {type === "image" ? (
          /* eslint-disable-next-line @next/next/no-img-element */
          <img
            src={src[Math.min(breakpointIndex, src.length - 1)]}
            className={classNames("object-cover")}
            alt={campaign}
          />
        ) : (
          <video
            autoPlay
            muted
            playsInline
            loop
            poster={
              poster
                ? poster[Math.min(breakpointIndex, poster.length - 1)]
                : undefined
            }
          >
            <source
              src={src[Math.min(breakpointIndex, src.length - 1)]}
              type="video/mp4"
            />
          </video>
        )}
      </a>
    </div>
  );
}

GenericAd.messages = [`${MESSAGE_PREFIX}GenericAd`];
