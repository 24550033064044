import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { NextIntlClientProvider } from "next-intl";
import { useEffect } from "react";

import "../styles/globals.css";

import { CookieBanner } from "@shared/components";
import IntercomProvider from "@shared/providers/IntercomProvider";
import MyGrowthBookProvider from "@shared/providers/MyGrowthBookProvider";
import {
  areCookiesAccepted,
  acceptAllCookies,
  pageView,
  storeUtmParams,
} from "@shared/tracking";

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    storeUtmParams(window.location.search.split("?")[1]);
    if (areCookiesAccepted()) {
      acceptAllCookies();
    }
  }, []);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <Head>
        <meta property="og:type" content="website" key="og-type" />
        <meta property="og:site_name" content="Picter" key="og-site_name" />
      </Head>
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale="en"
        // Get the current timezone from the browser
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
          autoBoot
          initializeDelay={2000}
          autoBootProps={{ hideDefaultLauncher: true }}
        >
          <MyGrowthBookProvider>
            <Component {...pageProps} />
            {/*
              For some reason, messages can be undefined sometimes. This seems to
              happen on revalidation only (when the json payload of a page is requested)
            */}
            {pageProps.messages && <CookieBanner />}
          </MyGrowthBookProvider>
        </IntercomProvider>
      </NextIntlClientProvider>
    </>
  );
}

App.messages = [...CookieBanner.messages];
