import React from "react";

function PicterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#7C3AED"
        fillRule="evenodd"
        d="M8 16a8 8 0 10-4.706-1.53V8a4.706 4.706 0 112.588 4.204v3.513A8.008 8.008 0 008 16z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PicterIcon;
