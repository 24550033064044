import React from "react";

type ULProps = {
  ordered: boolean;
  [key: string]: any;
};
/**
 * `omit` is not working for extracting ordered prop from all props
 * that's why we implemented following approach. Kindly refer to this link.
 * https://github.com/orgs/remarkjs/discussions/873
 */
export function UL({
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars  */
  ordered,
  ...props
}: ULProps) {
  return <ul className="list-disc pl-4 whitespace-normal" {...props} />;
}
