import classNames from "clsx";
import { PropsWithChildren, forwardRef } from "react";

import { As } from "./types";

type Size =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl";

export type TextProps = PropsWithChildren<{
  className?: string;
  size?: Size;
  color?:
    | "default"
    | "muted"
    | "disabled"
    | "danger"
    | "warning"
    | "current"
    | "custom"
    | "primary";
  weight?: "regular" | "medium" | "bold" | "semibold";
  font?: "display" | "body";
  id?: string;
  as?: As;
}>;

export default forwardRef<unknown, TextProps>(function Text(
  {
    children,
    className,
    size = "base",
    color = "default",
    font = "display",
    weight = "regular",
    as: Comp = "div",
    ...rest
  }: TextProps,
  ref,
) {
  return (
    <Comp
      ref={ref}
      className={classNames(
        {
          // sizes
          "text-xs": size === "xs",
          "text-sm leading-5": size === "sm",
          "text-base": size === "base",
          "text-lg leading-6": size === "lg",
          "text-xl": size === "xl",
          "text-2xl": size === "2xl",
          "text-2xl sm:text-3xl": size === "3xl",
          "text-3xl sm:text-4xl": size === "4xl",
          "text-4xl sm:text-5xl": size === "5xl",
          "text-4xl sm:text-6xl": size === "6xl",
          // colors
          "text-current": color === "current",
          "text-gray-900 dark:text-gray-100": color === "default",
          "text-gray-500": color === "muted",
          "text-gray-300": color === "disabled",
          "text-red-600": color === "danger",
          "text-amber-500": color === "warning",
          "text-primary-600": color === "primary",
          // font
          "font-display": font === "display",
          "font-body": font === "body",
          // weight
          "font-normal": weight === "regular",
          "font-medium": weight === "medium",
          "font-bold": weight === "bold",
          "font-semibold": weight === "semibold",
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Comp>
  );
});
