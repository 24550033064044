import classNames from "clsx";
import { useTranslations } from "next-intl";
import { HiExternalLink } from "react-icons/hi";

import { Badge, Text } from "@shared/elements";
import { useAdImpressionTracking } from "@shared/tracking";

import { MESSAGE_PREFIX } from "./constants";

type AdCardProps = {
  className?: string;
  title: string;
  url: string;
  campaign: string;
  src: string;
  poster?: string;
  type: "video" | "image";
  clickUrl: string;
  impressionUrl: string;
};

export default function AdCard({
  className,
  title,
  url,
  src,
  poster,
  type,
  campaign,
  impressionUrl,
  clickUrl,
  ...rest
}: AdCardProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}AdCard`);
  const ref = useAdImpressionTracking({
    impressionUrl: impressionUrl,
  });

  return (
    <div
      className={classNames("h-full flex flex-col justify-between", className)}
      {...rest}
    >
      <a href={clickUrl} ref={ref}>
        <div className="block aspect-w-4 aspect-h-3 w-full overflow-hidden mb-4 shadow-md">
          {type === "image" ? (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img src={src} className="object-cover" alt={campaign} />
          ) : (
            /* eslint-disable-next-line jsx-a11y/media-has-caption */
            <video autoPlay muted playsInline loop poster={poster}>
              <source src={src} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="flex items-center justify-between mb-3">
          <Badge color="primary" size="xs">
            {t("badge")}
          </Badge>
        </div>
        <Text as="h2" size="lg" weight="medium" className="mb-2">
          {title}
        </Text>

        <Text size="sm" color="muted" weight="medium" className="inline-flex">
          <HiExternalLink
            className="mr-1 text-gray-400 inline-block w-5 h-5"
            size="0.7rem"
          />
          {new URL(url).host.replace(/^www\./, "")}
        </Text>
      </a>
    </div>
  );
}

AdCard.messages = [`${MESSAGE_PREFIX}AdCard`];
