export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

if (!process.env.NEXT_PUBLIC_MAIN_APP_URL) {
  throw new Error("NEXT_PUBLIC_MAIN_APP_URL is not set");
}

if (!process.env.NEXT_PUBLIC_CALL_PAGE_APP_URL) {
  throw new Error("NEXT_PUBLIC_CALL_PAGE_APP_URL is not set");
}

if (!process.env.NEXT_PUBLIC_SUBMISSION_APP_URL) {
  throw new Error("NEXT_PUBLIC_SUBMISSION_APP_URL is not set");
}

if (!process.env.NEXT_PUBLIC_SIMPLIFIED_SUBMISSION_APP_URL) {
  throw new Error("NEXT_PUBLIC_SIMPLIFIED_SUBMISSION_APP_URL is not set");
}

export const urls = {
  index: `${process.env.NEXT_PUBLIC_MAIN_APP_URL}/`,
  login: `${process.env.NEXT_PUBLIC_MAIN_APP_URL}/login`,
  // Note: URLSearchParams is auto encoding the values
  submissionLogin: (callId: string, searchParams?: URLSearchParams) => {
    const params = new URLSearchParams(searchParams);
    params.set("callId", callId);
    return `${
      process.env.NEXT_PUBLIC_MAIN_APP_URL
    }/submission-login?${params.toString()}`;
  },
  signup: `${process.env.NEXT_PUBLIC_MAIN_APP_URL}/signup`,

  callPage: (slug: string) =>
    `${process.env.NEXT_PUBLIC_CALL_PAGE_APP_URL}/${slug}`,

  // Submission actions
  startSubmission: (
    callId: string,
    /**
     * Has the user agreed to be contacted by the contest host?
     */
    contactAgreed = false,
  ) =>
    `${process.env.NEXT_PUBLIC_SUBMISSION_APP_URL}/contests/${callId}/start${
      contactAgreed ? "?contactAgreed=true" : ""
    }`,
  finishSubmission: (callId: string) =>
    `${process.env.NEXT_PUBLIC_SUBMISSION_APP_URL}/submission/${callId}/submit`,
  viewSubmission: (callId: string) =>
    `${process.env.NEXT_PUBLIC_SUBMISSION_APP_URL}/submission/${callId}/summary`,

  startSimplifiedSubmission: (slug: string) => {
    return `${process.env.NEXT_PUBLIC_SIMPLIFIED_SUBMISSION_APP_URL}/${slug}`;
  },
  mySubmissions: `${process.env.NEXT_PUBLIC_SUBMISSION_APP_URL}/contests/submitted`,
  // Legacy account app, no staging or development environment available
  profile: "https://account.picter.com/profile",
};
