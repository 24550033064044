import isEmpty from "lodash/isEmpty";

import { toQueryString } from "@shared/utils";

import { UTM_PARAMS_SESSION_STORAGE_KEY } from "./constants";

type TUtmParams = {
  utm_source: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

const defaultUtmParams: TUtmParams = {
  utm_source: "picter",
  utm_medium: undefined,
  utm_campaign: undefined,
  utm_term: undefined,
  utm_content: undefined,
};

const utmDefaultKeys: Array<string> = Object.keys(defaultUtmParams);

/**
 * Store UTM parameters in session storage, if they are present in the query string.
 * This allows us to append them to links when the user navigates to another page
 * (e.g. the submission wizard). This is important for tracking the source of the user
 * across different pages on our side.
 */
export function storeUtmParams(queryString: string) {
  if (isEmpty(queryString)) return;
  const searchParams = new URLSearchParams(queryString);
  const utmParams = Array.from(searchParams.keys())
    .filter((key: string) => utmDefaultKeys.includes(key))
    .reduce((acc, val) => ({ ...acc, [val]: searchParams.get(val) }), {});
  Object.keys(utmParams).length > 0 &&
    sessionStorage.setItem(
      UTM_PARAMS_SESSION_STORAGE_KEY,
      JSON.stringify(utmParams),
    );
}

/**
 * Get UTM parameters from session storage. If they are not present, return the default values.
 */
export function getUtmParams() {
  let params: TUtmParams | undefined;
  try {
    params = JSON.parse(
      sessionStorage.getItem(UTM_PARAMS_SESSION_STORAGE_KEY) || "",
    );
  } catch (error) {
    params = undefined;
  }
  return { ...defaultUtmParams, ...params };
}

/**
 * Safely append UTM parameters to a URL without overwriting existing query parameters.
 */
export function appendUtmParams(url: string, params: TUtmParams) {
  const [baseURL, query] = url.split("?");
  const existingParams = Object.fromEntries(
    new URLSearchParams(query).entries(),
  );
  return `${baseURL}?${toQueryString({
    ...existingParams,
    ...params,
  })}`;
}
